import * as Papa from 'papaparse';
import Immerable from '../store/immerable';

import { AzureFileInfo, AzureShareInfo } from '../web-apis/azure-files';
import { RoiMapping } from './dataset-files';
import { DatasetImage } from './dataset-image';
import { DatasetMetaFiles } from './dataset-metafiles';
import { DatasetStructureSet } from './dataset-structure-set';
import _ from 'lodash';
import { getScanId } from '../store/scans';

const csvHeaders = {
    patientId: 'patient_id',
    modality: 'modality',
    frameOfReferenceUid: 'for_uid',
    seriesId: 'series_uid',
    seriesDescription: 'series_desc',
    pixelDataCharacteristics: 'pixel_data',
    patientExam: 'patient_exam',
    modalitySpecific: 'modality_specific',
    implementationSpecific: 'implementation_specific',
    ssScanId: 'rt_ref_series_uid',
    ssSeriesId: 'rt_series_uid',
    ssSopId: 'rt_sop_uid',
    ssLabel: 'rt_label',
    ssApproval: 'rt_approval',
    ssBestMatch: 'rt_best_match',
    ssStandardRoisString: 'rt_standard_rois',
    ssOriginalRoisString: 'rt_original_rois',
}

/**
 * Annotation dataset. This object generally matches a .csv file in some azure dataset location.
 * A dataset contains references to multiple (annotation) images, and structure sets related to
 * those images. Datasets also contain grading sheets for annotations.
 */
export class Dataset extends Immerable {

    // headers from the original csv file -- needed for re-serialization
    private csvHeader: string[];

    // the original azure file location for this dataset file
    // this file will uniquely identify this dataset
    public datasetFile: AzureFileInfo;

    // optional specific dataset ID. If not defined, one will be derived from the dataset file.
    private datasetId?: string;

    // flat list of all images in this dataset
    public images: DatasetImage[];

    public standardRois: Set<string>;
    public originalRois: Set<string>;
    public metaFiles: DatasetMetaFiles;

    constructor(csv: Papa.ParseResult<any>, datasetFile: AzureFileInfo, datasetId?: string) {
        super();

        this.csvHeader = csv.data[0];
        this.datasetFile = datasetFile;
        this.datasetId = datasetId;
        this.images = [];
        this.standardRois = new Set<string>();
        this.originalRois = new Set<string>();
        this.metaFiles = new DatasetMetaFiles(null, null, null, null);

        this.populateImageList(csv, datasetFile);
    }

    getDatasetId(): string {
        return this.datasetId ? this.datasetId : Dataset.generateDatasetId(this.datasetFile.getShare());
    }

    getImage(scanId: string): DatasetImage | null {
        const matchingImage = this.images.find(i => i.scanId === scanId);
        return matchingImage ? matchingImage : null;
    }

    private populateImageList(csv: Papa.ParseResult<any>, datasetFile: AzureFileInfo) {
        for (let i = 1; i < csv.data.length; ++i) {
            const data = csv.data[i];
            if (data.length === 1) break; // Last line seems to be an empty string

            // TODO: use a proper model here
            const headerIndices = this.findHeaderIndices(this.csvHeader);
            const parse = (attrib: string) => _.get(data, `${headerIndices[attrib]}`, '');

            const patientId = parse('patientId');
            const modality = parse('modality');
            const frameOfReferenceUid = parse('frameOfReferenceUid');
            const seriesId = parse('seriesId');
            const seriesDescription = parse('seriesDescription');
            const pixelDataCharacteristics = parse('pixelDataCharacteristics');
            const patientExam = parse('patientExam');
            const modalitySpecific = parse('modalitySpecific');
            const implementationSpecific = parse('implementationSpecific');

            const ssImageSeriesId = parse('ssScanId');
            const ssSeriesId = parse('ssSeriesId');
            const ssSopId = parse('ssSopId');
            const ssLabel = parse('ssLabel');
            const ssApproval = parse('ssApproval');
            const ssStandardRoisString = parse('ssStandardRoisString');
            const ssOriginalRoisString = parse('ssOriginalRoisString');

            // note: incoming ssBestMatch value can be a python boolean, e.g. "True" or "False" with a capital first letter
            const bestMatchValue = parse('ssBestMatch');
            const ssBestMatch = _.isString(bestMatchValue) && bestMatchValue.localeCompare('true', undefined, { sensitivity: 'accent' }) === 0;

            const scanId = getScanId(seriesId, patientId, datasetFile);

            const existing = this.images.filter(img => img.scanId === scanId);
            const datasetImage = existing.length ? existing[0] : new DatasetImage(this.getDatasetId(), scanId, patientId, modality, frameOfReferenceUid, seriesId, seriesDescription, pixelDataCharacteristics, patientExam, modalitySpecific, implementationSpecific);
            if (!existing.length) {
                this.images.push(datasetImage);
            }

            if (ssImageSeriesId === '') { continue; /*Image doesn't have rtstructs*/ }

            let standardRois = ssStandardRoisString ? this.parseRoiList(ssStandardRoisString) : [];
            let originalRois = ssOriginalRoisString ? this.parseRoiList(ssOriginalRoisString) : [];
            const mappings: RoiMapping[] = [];

            // both ROI lists must have been defined successfully, otherwise just put in empty lists and proceed
            if (standardRois === null || originalRois === null) {
                standardRois = [];
                originalRois = [];
            }
            else {
                if (originalRois.length !== standardRois.length) {
                    console.error("Row " + i + ": Standard rois list length differs from original rois list length (" + standardRois.length + " and " + originalRois.length + ")");
                }
                for (let i = 0; i < standardRois.length; ++i) {
                    mappings.push(new RoiMapping(originalRois[i], standardRois[i]));
                }
                standardRois.forEach(this.standardRois.add, this.standardRois);
                originalRois.forEach(this.originalRois.add, this.originalRois);
            }

            const ss = new DatasetStructureSet(this.getDatasetId(), datasetImage.seriesId, scanId, ssSeriesId, ssSopId, ssImageSeriesId, frameOfReferenceUid, ssApproval, ssLabel, ssBestMatch, mappings);
            if (ss.scanId === datasetImage.scanId) { // Filter out structure sets that have been related to the image but are not created for that image
                datasetImage.structureSets.push(ss);
            }
            else {
                datasetImage.otherStructureSets.push(ss);
            }
        }
    }

    serializeImageList(): string {
        const serialization: string[][] = [];
        serialization.push(this.csvHeader);

        for (let i = 0; i < this.images.length; ++i) {
            const img = this.images[i];

            const imageCsvValues = [img.patientId, img.modality, img.frameOfReferenceUid, img.seriesId, img.seriesDescription, img.pixelDataCharacteristics, img.patientExam, img.modalitySpecific, img.implementationSpecific];
            const rtStructEmptyValues = ["", "", "", "", "", "False", "", ""];

            const ssList = img.structureSets.concat(img.otherStructureSets).sort(this.serializationCompare);
            if (ssList.length === 0) {
                serialization.push(imageCsvValues.concat(rtStructEmptyValues));
            }
            else {
                for (let j = 0; j < ssList.length; ++j) {
                    const ss = ssList[j];
                    const bestMatchString = ss.bestMatch ? "True" : "False";
                    const standardRoisString = '[' + ss.roiMappings.map(m => "'" + m.standardName + "'").join(", ") + ']';
                    const originalRoisString = '[' + ss.roiMappings.map(m => "'" + m.originalName + "'").join(", ") + ']';
                    const rtStructCsvValues = [ss.ssImageSeriesId, ss.seriesId, ss.sopId, ss.label, ss.approvalStatus, bestMatchString, standardRoisString, originalRoisString];
                    serialization.push(imageCsvValues.concat(rtStructCsvValues));
                }
            }
        }

        // TODO: check that this works after the change
        return Papa.unparse(serialization);
    }

    private findHeaderIndices(headerRow: string[]) {
        const headerIndices: { [headerKey: string]: number } = {};
        for (const [headerKey, headerValue] of Object.entries(csvHeaders)) {
            headerIndices[headerKey] = headerRow.indexOf(headerValue);

        }
        return headerIndices;
    }

    private parseRoiList(str: string): string[] | null {
        str = str.replace(/"/g, " ").replace(/'/g, '"').replace('\\', '\\\\'); //"['Lung_L', 'Lung_R', 'Heart', 'Esophagus', 'Trachea', 'Spinal_Cord', 'GTV']"
        let rois: any;
        try {
            rois = JSON.parse(str);
        }
        catch (err) {
            console.error(`An error occurred when trying to parse JSON from converted ROI list (${str}):\n${err}`);
            return null;
        }
        if (!Array.isArray(rois)) { throw new Error("Invalid ROI list"); }
        return rois;
    }

    private serializationCompare(a: DatasetStructureSet, b: DatasetStructureSet) { // Try to sort structure sets so that "most usable ones first"
        if (a.bestMatch && !b.bestMatch) return -1;
        if (b.bestMatch && !a.bestMatch) return 1;
        if (a.approvalStatus === "APPROVED" && b.approvalStatus !== "APPROVED") return -1;
        if (b.approvalStatus === "APPROVED" && a.approvalStatus !== "APPROVED") return 1;
        if (a.approvalStatus === "UNAPPROVED" && b.approvalStatus === "REJECTED") return -1;
        if (b.approvalStatus === "UNAPPROVED" && a.approvalStatus === "REJECTED") return 1;
        if (a.label !== b.label) return a.label.localeCompare(b.label);
        return a.sopId.localeCompare(b.sopId);
    }

    public static generateDatasetId(fileShare: AzureShareInfo) {
        return fileShare.toString();
    }
}
