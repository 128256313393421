import { channel } from 'redux-saga'
import { put, all, take, fork, call, delay, race, select, spawn, debounce } from 'redux-saga/effects'
import * as Store from './store';
import { Dispatch } from 'redux'
import JSZip from 'jszip';
import { Location } from 'history';

import { StructureSet, dicomToStructureSet, structureSetToDicom, StructureSetModalMessages } from '../dicom/structure-set';
import { dicomToImageSlice, Image, ImageSlice } from '../dicom/image';
import { LiveReviewQueryV1, LiveReviewQueryV2 } from './live-review-query';
import { sleep, convertToFileSafe, saveFileOnUserDevice, getFilenameSafeTimestamp, lpad } from '../util';
import { ContouringClient } from '../web-apis/contouring-client';
import { ContouringTaskState } from './contouring-task';
import { BatchClient, BatchJobStatus } from '../web-apis/batch-client';
import * as selectors from './selectors';
import { compressGZip, decompressGZip } from './gzip'
import { anonymizeSlice, DicomMapAnonReal } from '../dicom/image_anonymization';
import { rtViewerApiClient, RoiGuidelines, RoiNameMap } from '../web-apis/rtviewer-api-client';
import { StructureTemplate } from '../web-apis/structure-template';
import { defaultAuths, isDemo, setRTViewerBackend } from '../environments';
import { RTViewerWebClient } from '../web-apis/rtviewer-web-client';
import { AppVersionInfo } from './app-version-info';
import { getAppAuthByName, getAppAuthByTier, getBackendClient, getOptionalAuths, getRequiredLiveReviewBackend, getRequiredLiveReviewBackendClient } from '../web-apis/auth';
import { AppAuthState, LogInProcessState } from './auth-state';
import { setLiveReviewIsDownloading, setLiveReviewQueries, setUserSettingPatientInfoVisibility } from './store';
import { Backend, BackendDefinition } from '../web-apis/backends';
import { LiveReviewClient } from '../web-apis/livereview-client';
import { User } from './user';
import { UserPermissions } from '../web-apis/user-permissions';
import AppAuth, { POPUPS_BLOCKED_ERROR } from '../web-apis/app-auth';
import { AzureDownloadError, LoginError, ApiDownloadError } from './errors';
import { AzureFileInfo, AzureShareInfo, AzurePathInfo } from '../web-apis/azure-files';
import { AzureFileClient } from '../web-apis/azure-file-client';
import { AzureFileRequest, AzureFileShareRequest, LiveReviewV1FileRequest, LiveReviewV2FileRequest } from './requests';
import { ReceivedApiFile, ReceivedAzureFile } from './received-files';
import { getAreConcurrentUploadsEnabled } from '../local-storage';
import { downloadDatasetInfo, downloadDatasetLocks, ScanLocks, downloadGradings, DEBOUNCE_GRADINGS_SAVE_MS, GradingToSave } from '../datasets/dataset-files';
import { Dataset } from '../datasets/dataset';
import { DatasetImage } from '../datasets/dataset-image';
import * as datasetFiles from '../datasets/dataset-files';
import WorkState, { Workspace } from './work-state';
import { DatasetGradings, StructureSetGrading, convertWorkflowStateToText } from '../datasets/roi-grading';
import { backupGradings } from '../datasets/backups';
import { NotificationType, SessionNotification, DEFAULT_SESSION_TIMEOUT_IN_MS } from '../components/common/models/SessionNotification';
import { ViewerState } from '../rtviewer-core/viewer-state';
import _ from 'lodash';
import { PagePatient } from '../components/annotation-page/models/PagePatient';
import { ComparisonSelectorCollection } from '../components/rtviewer/toolbars/comparison-types';
import { TrainingTask, TrainingTasks, downloadTasks, getTaskDownloadKey, SortType, downloadArchivedTasks, TrainingTaskState, getUserCanEditTask, Filter } from '../datasets/training-task';
import { RTStructLock, RTStructLockDictionary, LockAction, generateRTStructLockForTask } from '../datasets/locks';
import { fixMissingMetaheader } from '../dicom/utils';
import { getScanId } from './scans';
import { TrainingUser } from './training-user';
import { UrlQuery, UrlQueryType } from './url-query';
import { DeploymentConfigInfo } from './deployment-config-info';
import { LabelingInfo } from './labeling-info';


const MAX_DOWNLOAD_RETRY_ATTEMPTS = 5;


export const mapDispatchToProps = (dispatch: Dispatch) => ({

    initializeApp(routeLocation: Location) {
        dispatch({ type: Store.initializeApp, location: routeLocation });
    },

    storeFullImage(img: Image) {
        dispatch({ type: Store.receiveFullImageType, image: img });
    },

    storeLocalScan(files: File[], cbReturnId: (scanId: string) => void) {
        if (!files || files.length === 0) {
            return;
        }
        let len = files.length;
        let scanId = '';
        function storeFile(file: File, arrayBuffer: ArrayBuffer, progress: number, isLastSlice: boolean) {

            dispatch({ type: Store.setLocalFileProgress, progress: progress, total: len });

            try {
                if (file.name.toLowerCase().endsWith('.gz')) { arrayBuffer = decompressGZip(arrayBuffer); }
                let slice: ImageSlice | null = null;

                try {
                    slice = dicomToImageSlice(arrayBuffer);
                }
                catch (err) {
                    // catch the headerless dicom file error from dcmjs and try to fix it
                    // also, regarding the error message: sic
                    if (err.message === 'Invalid a dicom file') {
                        // try fixing the dicom file manually
                        arrayBuffer = fixMissingMetaheader(arrayBuffer);
                        slice = dicomToImageSlice(arrayBuffer);
                    } else {
                        throw err;
                    }
                }

                if (slice) {
                    scanId = getScanId(slice);
                    dispatch({ type: Store.receiveSliceType, arrayBuffer: arrayBuffer, imageSlice: slice, filename: file.name });
                } else {
                    // Allow loading a structure set together with the image slices
                    let ss = dicomToStructureSet(arrayBuffer);
                    if (ss) {
                        const filename = file.name.toLowerCase().endsWith('.gz') ? file.name.slice(0, -3) : file.name;
                        dispatch({ type: Store.storeOriginalStructureSet, structureSetId: ss.structureSetId, file: arrayBuffer, filename: filename });
                        dispatch({ type: Store.receiveStructureSetType, structureSet: ss, filename: file.name });
                    }
                }
                if (isLastSlice) {
                    cbReturnId(scanId);
                }
            } catch (error) {
                console.error(error);
                const message: string = _.has(error, 'message') ? error.message : error;
                dispatch({ type: Store.fileLoadError, errorMessage: message, fileName: file.name });
            }
        }
        function loopFiles(i: number) {
            let reader = new FileReader();
            reader.onload = function (e) {
                let res: any = reader.result;
                storeFile(files[i], res, i + 1, i === len - 1);
                if (++i < len) {
                    loopFiles(i);
                }
            }
            reader.readAsArrayBuffer(files[i]);
        }

        dispatch({ type: Store.resetLocalFileProgress });

        loopFiles(0);
    },

    unloadScan(scanId: string) {
        dispatch({ type: Store.unloadScanType, scanId: scanId });
    },

    unloadStructureSet(scanId: string, structureSetId: string) {
        dispatch({ type: Store.unloadStructureSetType, scanId: scanId, structureSetId: structureSetId });
    },

    storeStructureSet(arrayBuffer: ArrayBuffer, currentScanId: string, filename: string | null, isAutoContoured: boolean,
        generateNewAzureFileInfo: (seriesId: string, sopId: string) => AzureFileInfo | null,
        cbReturnId: (scanId: string, ssId: string, structureSet: StructureSet) => void,
        storeOriginalStructureSet = true, isComparisonStructureSet = false) {

        if (!arrayBuffer) {
            return;
        }

        let structureSet: StructureSet | null = null;

        try {
            structureSet = dicomToStructureSet(arrayBuffer);
        }
        catch (error) {
            console.error(error);
            const message: string = _.has(error, 'message') ? error.message : error;
            dispatch({ type: Store.fileLoadError, errorMessage: message, fileName: filename || '' });
        }

        if (structureSet) {

            const azureFileInfo = generateNewAzureFileInfo(structureSet.seriesUid, structureSet.structureSetId);

            // check that the structure set we're about to store matches with the current scan (id)
            // (unless we're working with a transient comparison structure set -- that always has a unique scan id)
            if (!isComparisonStructureSet && structureSet.scanId !== currentScanId) {
                // first try updating the scanId from azure file info
                structureSet.scanId = getScanId(structureSet, azureFileInfo || undefined);

                if (structureSet.scanId !== currentScanId) {
                    // if still no go, then dispatch an error
                    dispatch({ type: Store.fileLoadError, errorMessage: "Structure set belongs to different image. scan: " + currentScanId + ', RTSTRUCT: ' + structureSet.scanId, fileName: filename || '' });
                    return;
                }
            }

            let extraOptions: any = {};
            if (filename !== null) {
                extraOptions['filename'] = filename;
            }

            // NOTE: as of now this is only relevant for annotation use cases, not all "auto-contoured" cases despite the variable name
            if (isAutoContoured) {
                structureSet.existsInAzure = false;
                structureSet.unsaved = true;
                structureSet.azureFileInfo = azureFileInfo;
            }

            if (storeOriginalStructureSet) {
                // this method should only ever receive uncompressed arraybuffers, so treat the default file extension as so
                const originalFilename = filename || `structureset_${convertToFileSafe(structureSet.getLabel())}.dcm`;
                dispatch({ type: Store.storeOriginalStructureSet, structureSetId: structureSet.structureSetId, file: arrayBuffer, filename: originalFilename });
            }

            // don't store comparison structure sets under scans
            if (!isComparisonStructureSet) {
                dispatch({ type: Store.receiveStructureSetType, arrayBuffer: arrayBuffer, structureSet: structureSet, isComparisonStructureSet: isComparisonStructureSet, ...extraOptions });
            }

            cbReturnId(structureSet.scanId, structureSet.structureSetId, structureSet);
        }
        else {
            dispatch({ type: Store.fileLoadError, errorMessage: "Invalid DICOM structure set", fileName: filename || '' });
        }
    },

    hideSidebar(hide: boolean) {
        dispatch({ type: Store.hideSide, hide: hide });
    },

    storeLocalStructureSet(file: File, currentScanId: string, cbReturnId: (scanId: string, ssId: string, structureSet: StructureSet) => void) {
        if (!file) {
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            let arrayBuffer = reader.result as ArrayBuffer;
            if (file.name.toLowerCase().endsWith('.gz')) arrayBuffer = decompressGZip(arrayBuffer)
            this.storeStructureSet(arrayBuffer, currentScanId, file.name, false, () => null, cbReturnId);
        }
        reader.readAsArrayBuffer(file);
    },

    clearFileLoadErrors() {
        dispatch({ type: Store.clearFileLoadErrors });
    },

    unloadImageAndStructureSets(datasetImage: DatasetImage) {
        dispatch({ type: Store.unloadScanType, scanId: datasetImage.scanId });
        for (let i = 0; i < datasetImage.structureSets.length; ++i) {
            const ss = datasetImage.structureSets[i];
            dispatch({ type: Store.unloadStructureSetType, scanId: datasetImage.scanId, structureSetId: ss.sopId });
        }
        dispatch({ type: Store.deleteDownloadType, downloadKey: datasetImage.downloadKey });
    },


    setCurrentTask(task: TrainingTask | undefined) {
        dispatch({ type: Store.setCurrentTask, task });
    },

    openTask(task: TrainingTask | undefined) {
        dispatch({ type: Store.openTask, task });
    },

    async deleteStructureSet(structureSet: StructureSet): Promise<void> {

        // if structure set has been saved to azure, those external files must be removed
        // (if not, it's enough to handle the structure sets internally in-memory)
        if (structureSet.existsInAzure) {
            if (!structureSet.azureFileInfo) {
                throw new Error("azureFileInfo is null");
            }

            const accountName = structureSet.azureFileInfo.storageAccountName;
            const azureFileClient = new AzureFileClient(accountName);
            const path = azureFileClient.getPath(structureSet.azureFileInfo.fileShareName, structureSet.azureFileInfo.path);

            const directoryExists = await azureFileClient.doesDirectoryExist(path);
            if (directoryExists) {
                await azureFileClient.deleteDirectoryRecursive(path);
            }
        }

        dispatch({ type: Store.deleteStructureSetType, structureSet: structureSet });
    },

    async uploadStructureSet(structureSet: StructureSet, img: any, saveToAzure = false) {

        // TODO: not sure why this sleep is here -- investigate if it's necessary
        await sleep(50);

        if (!structureSet.azureFileInfo) {
            throw new Error("azureFileInfo is null");
        }

        const fileInfo = structureSet.azureFileInfo;
        const pathInfo = fileInfo.getPath();

        // username should eventually be retrieved from redux store instead
        const username = rtViewerApiClient.username;

        const ab = structureSetToDicom(structureSet, img);

        if (saveToAzure) {
            // old implementation: save directly to azure
            const useGzip = fileInfo.filename.toLowerCase().endsWith(".gz");
            const blob = new Blob([useGzip ? compressGZip(ab) : ab], { type: "" });
            const azureFileClient = new AzureFileClient(pathInfo, { username });
            await azureFileClient.createDirIfNotExists(pathInfo);
            await azureFileClient.saveBlob(fileInfo, blob);
        } else {
            // new implementation: save through backend

            // gzip the file, always. make sure the file name has .gz extension
            const blob = new Blob([compressGZip(ab)], { type: "" });
            const newFileInfo = new AzureFileInfo(fileInfo.getPath(), fileInfo.filename.endsWith('gz') ? fileInfo.filename : `${fileInfo.filename}.gz`);
            await rtViewerApiClient.saveRTStruct(blob, newFileInfo);
        }

        structureSet.unsaved = false;
        structureSet.getRois().forEach(roi => { roi.unsaved = false });
        structureSet.existsInAzure = true;
        structureSet.arrayBuffer = ab;
    },

    storeNewStructureSet(structureSet: StructureSet) {
        dispatch({ type: Store.receiveStructureSetType, structureSet: structureSet });
    },

    undoStructureSetChanges(ss: StructureSet): StructureSet | null {
        if (ss.existsInAzure) {
            let ssNew = dicomToStructureSet(ss.arrayBuffer);
            if (ssNew) {
                ssNew.azureFileInfo = ss.azureFileInfo;
                ssNew.scanId = ss.scanId;
                dispatch({ type: Store.receiveStructureSetType, structureSet: ssNew });
                return ssNew;
            }
        }
        else {
            ss.deleted = true;
        }
        dispatch({ type: Store.deleteStructureSetType, structureSet: ss });
        return null;
    },

    async createDownloadTask(datasetImage: DatasetImage) {
        dispatch({ type: Store.receiveDownloadCreated, downloadKey: datasetImage.downloadKey });
    },

    async setPatientsPerPage(PagePatients: PagePatient[]) {
        dispatch({ type: Store.receivePagePatients, pagePatients: PagePatients });
    },

    sendImageForContouring(arrayBuffers: ArrayBuffer[], scanId: string, contouringAction: string, backend: Backend, dicomMapAnonReal: DicomMapAnonReal) {
        dispatch({ type: Store.receiveUploadCreated, scanId: scanId });
        let cnt = 0;
        const sliceCountTotal = arrayBuffers.length;
        arrayBuffers.forEach(ab => {
            const filename = (++cnt) + ".dcm";
            dispatch({ type: Store.receiveUploadAddFile, arrayBuffer: ab, dicomMapAnonReal: dicomMapAnonReal, filename: filename, scanId: scanId, contouringAction: contouringAction, backend: backend, sliceCountTotal: sliceCountTotal });
        });
    },

    clearAllContouringRequests: () => {
        dispatch({ type: Store.unloadAllContouringTasksType });
    },

    seenStructureSet: (structureSetId: string) => {
        dispatch({ type: Store.seenStructureSetType, structureSetId: structureSetId });
    },

    setContouringTaskState: (scanId: string, newContouringState: ContouringTaskState, errorMessage: string) => {
        dispatch({ type: Store.updateContouringTaskType, scanId, newContouringState, errorMessage });
    },

    dismissContouringTask: (scanId: string) => {
        dispatch({ type: Store.dismissContouringTaskType, scanId });
    },

    setBatchJobPaneVisibility(value: boolean) {
        dispatch({ type: Store.setBatchJobPaneVisibilityType, value });
    },

    setTasksDialogVisibility(value: boolean) {
        dispatch({ type: Store.setTasksDialogVisibilityType, value });
    },

    setTaskDescriptionDialogVisibility(value: boolean) {
        dispatch({ type: Store.setTaskDescriptionDialogVisibility, value });
    },

    setUserSettingsDialogVisibility(value: boolean) {
        dispatch({ type: Store.setUserSettingsDialogVisibilityType, value });
    },

    setSupervisorSettingsDialogVisibility(value: boolean) {
        dispatch({ type: Store.setSupervisorSettingsDialogVisibilityType, value });
    },

    setHelpDialogVisibility(value: boolean) {
        dispatch({ type: Store.setHelpDialogVisibility, value });
    },

    setArchivedTasksDialogVisibility(value: boolean) {
        dispatch({ type: Store.setArchivedTasksDialogVisibility, value });
    },

    setUserSettingPatientInfoVisibility(showPatientInfo: boolean) {
        dispatch({ type: setUserSettingPatientInfoVisibility, showPatientInfo });
    },

    setUserSettingBackend(backend: Backend | null) {
        dispatch({ type: Store.setUserSettingBackend, backend });

        // set the new backend immediately as being required so UI will prompt user to log
        // into it if necessary
        if (backend !== null) {
            const appAuth = getAppAuthByTier(backend.tier);
            dispatch({ type: Store.setAuthStateAsRequired, appAuthName: appAuth.appName });
        }
    },
    /** Ensures that a backend (or rather a backend's tier) has been logged into. */
    logIntoBackend(backend: Backend) {
        // set the new backend immediately as being required so UI will prompt user to log
        // into it if necessary
        const appAuth = getAppAuthByTier(backend.tier);
        dispatch({ type: Store.setAuthStateAsRequired, appAuthName: appAuth.appName });
    },

    startWatchingBatchJobs: () => {
        dispatch({ type: Store.receiveStartWatchingBatchJobsType });
    },

    startBatchJobRequest: () => {
        dispatch({ type: Store.batchJobRequestLockType, lock: true });
    },

    finishBatchJobRequest: (wasSuccessful: boolean) => {
        if (wasSuccessful) {
            dispatch({ type: Store.batchJobRequestSuccessType });
        } else {
            dispatch({ type: Store.batchJobRequestFailureType });
        }
    },

    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => {
        // they delay can be used to e.g. wait for a modal dialog to fade out before showing the notification
        const delay = delayInMilliseconds ? delayInMilliseconds : 0;
        setTimeout(() => dispatch({ type: Store.addNotificationType, notification }), delay);
    },

    dismissNotification: (notificationId: string) => {
        dispatch({ type: Store.removeNotificationType, notificationId });
    },

    loadStructureTemplates: () => {
        dispatch({ type: Store.receiveStartFetchingStructureTemplatesType });
    },

    snoozeNewVersionAlert: () => {
        dispatch({ type: Store.snoozeNewVersionAlert });
    },

    setLiveReviewQueries: (urlSearchParams: URLSearchParams): boolean => {
        let queryV1: LiveReviewQueryV1 | null = new LiveReviewQueryV1(urlSearchParams);
        if (!queryV1.isValid()) { queryV1 = null; }
        let queryV2: LiveReviewQueryV2 | null = new LiveReviewQueryV2(urlSearchParams);
        if (!queryV2.isValid()) { queryV2 = null; }

        dispatch({ type: setLiveReviewQueries, queryV1, queryV2 });

        // returns true if any query was valid, false otherwise
        return !!queryV1 || !!queryV2;
    },

    logIntoAppAuth: (appAuth: AppAuth) => {
        dispatch({ type: Store.setAuthStateAsRequired, appAuthName: appAuth.appName });
    },

    requestLogOut: () => {
        dispatch({ type: Store.requestLogOut });
    },

    /**
     * Downloads a Dataset from Azure and stores it into Redux Store. Any existing store entry with the same dataset ID
     * will be replaced.
     * @param azureShare Azure location of the dataset.
     * @param reloadMetaFiles If true, metadata files (gradings, allowed roi names, editor info) are loaded and replaced. 
     * If false, metadata file entries in the dataset are left as null.
     * @param datasetId ID which will be given to the downloaded dataset. This usually matches the azure share location (but it doesn't have to).
     */
    downloadDataset: (azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string) => {
        dispatch({ type: Store.requestDownloadDataset, azureShare, reloadMetaFiles, datasetId });
    },

    lockDatasetImage: async (datasetImage: DatasetImage, dataset: Dataset, lockAction: LockAction) => {
        dispatch({ type: Store.requestDatasetLock, datasetImage, dataset, lockAction });
    },

    fetchStructureSetLocks: () => {
        dispatch({ type: Store.getStructureSetLocks });
    },

    lockStructureSet(task: TrainingTask, user: User, lockAction: LockAction) {
        const rtStructLock = generateRTStructLockForTask(task, user);
        dispatch({ type: Store.requestStructureSetLock, rtStructLock, lockAction });

        if (lockAction === LockAction.Unlock) { this.unloadTaskImageAndStructureSets(task); }
    },

    async loadArchivedTasks() {
        const tasks = await downloadArchivedTasks();
        dispatch({ type: Store.downloadArchivedTasks, tasks: tasks });
    },

    downloadDatasetImage(dataset: Dataset, datasetImage: DatasetImage) {
        this.createDownloadTask(datasetImage);

        // Reload the dataset to ensure that will get all structure sets
        const reloadDataset = true;
        dispatch({ type: Store.requestImageAndStructureSetDownload, datasetImage, dataset, reloadDataset });
    },

    downloadTaskImageAndStructureSets(task: TrainingTask) {
        const taskId = getTaskDownloadKey(task);
        dispatch({ type: Store.receiveDownloadCreated, downloadKey: taskId });
        dispatch({ type: Store.requestTaskDownload, task });
    },

    unloadTaskImageAndStructureSets(task: TrainingTask) {
        const taskId = getTaskDownloadKey(task);
        const scanId = getScanId(task);
        dispatch({ type: Store.unloadScanType, scanId: scanId });
        for (const structureSetId of [task.traineeStructureSet.sopInstanceUid, task.gtStructureSet.sopInstanceUid]) {
            dispatch({ type: Store.unloadStructureSetType, scanId: scanId, structureSetId: structureSetId });
        }
        dispatch({ type: Store.deleteDownloadType, downloadKey: taskId });
    },

    /** Sets an entirely new work state as current work state.
     * @param workstate The work state to be set as 'current'. Use null to reset current work state to an "empty" work state.
     * @param resetToWorkspace Workspace that the "empty" work state is reset to if the workState param is null. Ignored if 
     * the workState param is not null.
     */
    setCurrentWorkState: (workState: WorkState | null, resetToWorkspace?: Workspace) => {
        const dispatchedWorkState = workState !== null ? workState : new WorkState(null, null, false, resetToWorkspace ? resetToWorkspace : Workspace.None, false)
        dispatch({ type: Store.setCurrentWorkState, workState: dispatchedWorkState });

        // clear modified dataset gradings from memory if we're resetting current work state
        if (workState === null) {
            dispatch({ type: Store.clearModifiedDatasetGradings });
        }
    },

    /** Updates selected properties only of the current work state. */
    updateCurrentWorkState: (updatedWorkStateProps: Partial<WorkState>) => {
        dispatch({ type: Store.updateCurrentWorkState, ...updatedWorkStateProps });
    },

    /**
     * Synchronizes a structure set's matching grading sheet, if any, to match
     * the current ROI set in the structure set.
     */
    syncStructureSetGrading: (structureSet: StructureSet, dataset: Dataset) => {
        dispatch({ type: Store.syncStructureSetGrading, structureSet, dataset });
    },

    /**
     * Sets a specific structure set grading sheet into local redux store WITHOUT saving the changes into
     * azure. The redux store will then be out of sync with the data in azure. This function should only 
     * be used for temporary operations that will be reverted or finalized immediately after.
     */
    setGradingWithoutSaving: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) => {
        const gradingsToSave: datasetFiles.GradingToSave[] = [{ ssId: structureSetId, ssGrading }];
        dispatch({ type: Store.setStructureSetGrading, gradingsToSave, dataset });
    },


    /**
     * Saves selected structure set grading sheet, new or updated, to disk (to azure).
     */
    saveGrading: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) => {
        dispatch({ type: Store.saveStructureSetGrading, structureSetId, ssGrading, dataset });
    },

    /**
     * Saves all supplied dataset gradings to matching grading sheet file on disk (azure).
     * This needs to be called directly in only very rare cases, usually SaveGrading will suffice.
     */
    saveDatasetGradings: (gradingsToSave: datasetFiles.GradingToSave[], dataset: Dataset) => {
        dispatch({ type: Store.saveDatasetGradings, gradingsToSave, dataset });
    },

    /**
     * Reloads dataset gradings for the specified dataset. It's important to do this after a context change
     * (such as changing the scan that's currently active) as saving grading sheets for an individual image
     * may leave the rest of the dataset gradings out of sync.
     */
    reloadDatasetGradings: (dataset: Dataset) => {
        dispatch({ type: Store.reloadDatasetGradings, dataset: dataset });
    },

    /**
     * Load a work state from an annotation query item.
     */
    loadAnnotationQuery: (annotationQuery: UrlQuery, workspace: Workspace) => {
        dispatch({ type: Store.loadAnnotationQuery, annotationQuery, workspace });
    },

    clearUrlQuery: () => {
        dispatch({ type: Store.clearUrlQuery });
    },

    getPatients: (patients: string[]) => {
        dispatch({ type: Store.downloadPatients, patients });
    },

    getGTRois: (rois: string[]) => {
        dispatch({ type: Store.downloadGTRois, rois });
    },

    getUsers: () => {
        dispatch({ type: Store.downloadUsers });
    },

    reloadTasks: () => {
        dispatch({ type: Store.loadTasks });
    },

    updateTask: (task: TrainingTask, update: Partial<TrainingTask>, noSuccessNotification: boolean = false) => {
        dispatch({ type: Store.updateTask, task, update, noSuccessNotification });
    },

    finishTask: (task: TrainingTask, image: Image, roiNameMaps: RoiNameMap[]) => {
        dispatch({ type: Store.finishTask, task, image, roiNameMaps });
    },

    startTask: (task: TrainingTask) => {
        dispatch({ type: Store.startTask, task });
    },

    startUpdatingTask: () => {
        dispatch({ type: Store.startUpdatingTask });
    },

    searchTasks: (search: string) => {
        dispatch({ type: Store.setTasksSearchText, searchText: search });
    },

    sortTasks: (sort: SortType) => {
        dispatch({ type: Store.setSortType, sortType: sort });
    },

    filterTasks: (filters: Filter[]) => {
        dispatch({ type: Store.setTasksFilters, tasksFilters: filters })
    },

    finishUpdatingTask: () => {
        dispatch({ type: Store.finishUpdatingTask });
    },

    deleteTask: (task: TrainingTask) => {
        dispatch({ type: Store.deleteTask, task });
    },

    archiveTask: (task: TrainingTask) => {
        dispatch({ type: Store.archiveTask, task });
    },

    unarchiveTask: (task: TrainingTask) => {
        dispatch({ type: Store.unarchiveTask, task });
    },

    canUserFinishTask: async (task: TrainingTask, user: User): Promise<[boolean, string | undefined]> => {

        if (task.type === 'PRACTICE') {
            // practice tasks can always be updated
            return [true, undefined];
        } else {
            if (user.permissions.isSupervisor) {
                // supervisors can always update tasks
                return [true, undefined];
            } else if (user.permissions.isTrainee && user.isTaskTrainee(task)) {
                // get latest version of the task from backend and ensure it's not already finished
                const matchingTask: TrainingTask | undefined = await rtViewerApiClient.getTask(task.id);
                if (matchingTask && (matchingTask.state === TrainingTaskState.Finished || matchingTask.state === TrainingTaskState.Graded)) {
                    // trainees can't update non-practice tasks that have already been finished
                    return [false, 'Task is already finished. Please reload your page to ensure you\'re using the latest update of the task.'];
                } else {
                    return [true, undefined];
                }
            }
        }

        // in undefined cases the task CAN'T be finished
        return [false, 'Task cannot be finished. Please reload your page to ensure you\'re using the latest update of the task.'];
    },



    /**
     * Convert a structure set into DICOM and export it to a backend API.
     */
    async exportStructureSet(structureSet: StructureSet, img: Image, grading: StructureSetGrading | undefined, vs: ViewerState, task: datasetFiles.ExportTask | null, backend: Backend,
        originalDicomFile?: { file: ArrayBuffer, filename: string }) {
        structureSet.modalMessage = StructureSetModalMessages.Exporting;
        vs.notifyListeners();

        await sleep(500); // This must be really long sleep, otherwise the modal dialog shows as almost transparent (slow fade-in animation)

        try {
            const useOriginalDicom = originalDicomFile !== undefined;

            if (!structureSet.azureFileInfo && !useOriginalDicom) {
                throw new Error("No source file specified for given structure set");
            }

            const useGzip = useOriginalDicom ? false : structureSet.azureFileInfo!.filename.toLowerCase().endsWith(".gz");

            // write workflow state (empty, approved, unapproved) into the DICOM's StructureSetDescription field
            // (we shouldn't use the actual ApprovalStatus field for this)
            const postOps = (dataset: any) => {
                if (grading) {
                    dataset.StructureSetDescription = convertWorkflowStateToText(grading.workflowState);
                }
            };

            const ab = useOriginalDicom ? originalDicomFile!.file : structureSetToDicom(structureSet, img, postOps);
            const dicomBlob = new Blob([useGzip ? compressGZip(ab) : ab], { type: "" });
            const dicomFilename = useOriginalDicom ? originalDicomFile!.filename : `${structureSet.dataset.StructureSetLabel || structureSet.structureSetId}${useGzip ? '.dcm.gz' : '.dcm'}`

            const defaultTask: datasetFiles.ExportTask = { 'app_id': 'n/a', 'app_name': 'n/a', 'client_id': 'n/a' };
            const taskBlob = new Blob([JSON.stringify(task !== null ? task : defaultTask)], { type: 'application/json' });
            const taskFilename = 'task';

            const liveReviewClient = new LiveReviewClient(getBackendClient(backend));
            const wasSuccessful = await liveReviewClient.exportStructureSet(dicomBlob, dicomFilename, taskBlob, taskFilename);

            let notification: SessionNotification;
            if (wasSuccessful) {
                notification = new SessionNotification(`export-ok-${Date.now().toString()}`, `Structure set ${structureSet.dataset.StructureSetLabel || ''} was exported successfully!`, NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS);
            } else {
                notification = new SessionNotification(`export-failed-${Date.now().toString()}`, `Something went wrong with export of structure set ${structureSet.dataset.StructureSetLabel || ''}`, NotificationType.Failure);
            }
            dispatch({ type: Store.addNotificationType, notification });
        }
        finally {
            structureSet.modalMessage = null;
            vs.notifyListeners();
        }
    },

    setTestAndReferenceStructureObjects: (testAndReferenceStructureObjects: ComparisonSelectorCollection) => {
        dispatch({ type: Store.setTestAndReferenceStructureObjects, ...testAndReferenceStructureObjects });
    },

    clearTestAndReferenceStructureObjects: () => {
        dispatch({ type: Store.setTestAndReferenceStructureObjects, testStructureSet: undefined, referenceStructureSet: undefined, testRoi: undefined, referenceRoi: undefined });
    },

    requestSaveScanToUserDisk(image: Image, anonymizeScan: boolean) {
        const scanId = image.scanId;
        const patientId = image.dicomTags['PatientID'];
        const seriesDescription = image.seriesDescription;
        const zipFilename = anonymizeScan ? `scan-${getFilenameSafeTimestamp()}.zip` : `${convertToFileSafe(`${patientId ? `${patientId}${seriesDescription ? '-' : ''}` : ''}${seriesDescription ? `${seriesDescription.substring(0, 35)}` : ''}`)}.zip`;
        dispatch({ type: Store.requestSaveScanToUserDisk, scanId, zipFilename, anonymizeScan });
    },

    changeIsContouringAllowedInStructureSetRois(structureSet: StructureSet, user: User, task: TrainingTask) {

        const roiIsContouringAllowedChanges: { roiName: string, isContouringAllowedChange: boolean | undefined }[] = [];

        // case 1: supervisor should not be able to modify trainee task rois

        if (user.permissions.isSupervisor && structureSet.structureSetId === task.traineeStructureSet.sopInstanceUid && !user.isTaskTrainee(task)) {
            const traineeRoiNames = task.traineeStructureSet.rois.map(r => r.roiName);
            for (const traineeRoi of structureSet.getRois().filter(r => traineeRoiNames.includes(r.name))) {
                roiIsContouringAllowedChanges.push({ roiName: traineeRoi.name, isContouringAllowedChange: false });
            }
        }

        if (roiIsContouringAllowedChanges.length > 0) {
            dispatch({ type: Store.changeIsContouringAllowedInStructureSetRois, structureSet, roiIsContouringAllowedChanges });
        }
    },

    clearIsContouringAllowedOverrideChanges(structureSet: StructureSet) {
        const roiIsContouringAllowedChanges: { roiName: string, isContouringAllowedChange: boolean | undefined }[] = [];
        for (const roi of structureSet.getRois()) {
            roiIsContouringAllowedChanges.push({ roiName: roi.name, isContouringAllowedChange: undefined });
        }
    },

    setAutoOpenTaskItem(taskId: string | undefined) {
        dispatch({ type: Store.setAutoOpenTaskItem, autoOpenTaskItem: taskId });
    },

    setIsAutoloading(isAutoloading: boolean) {
        dispatch({ type: Store.setIsAutoloading, isAutoloading: isAutoloading });
    },

});

// this is here to keep our redux-store and non-redux store users in sync
function* watchUserUpdate() {
    while (true) {
        // update the rtviewer api client user whenever the user in the redux store is updated
        yield take([Store.setUserDetails, Store.setUserPermissions, Store.setUserSettingBackend, Store.setCurrentTask]);
        const user: User = yield select(selectors.getUser());
        rtViewerApiClient.updateUser(user);
    }
}

function* watchReloadTasks() {
    while (true) {
        yield take(Store.loadTasks);
        yield spawn(loadTasks);
    }
}

function* watchUpdateTask() {
    while (true) {
        const action: { task: TrainingTask, update: Partial<TrainingTask>, noSuccessNotification: boolean } = yield take(Store.updateTask);
        yield spawn(updateTask, action.task, action.update, action.noSuccessNotification);
    }
}

function* watchStartTask() {
    while (true) {
        const action: { task: TrainingTask } = yield take(Store.startTask);
        yield spawn(startTask, action.task);
    }
}

function* watchFinishTask() {
    while (true) {
        const action: { task: TrainingTask, image: Image, roiNameMaps: RoiNameMap[] } = yield take(Store.finishTask);
        yield spawn(finishTask, action.task, action.image, action.roiNameMaps);
        // reload the tasks
    }
}

function* watchDeleteTask() {
    while (true) {
        const action: { task: TrainingTask } = yield take(Store.deleteTask);
        yield spawn(deleteTask, action.task);
    }
}

function* watchArchiveTask() {
    while (true) {
        const action: { task: TrainingTask } = yield take(Store.archiveTask);
        yield spawn(archiveTask, action.task);
    }
}

function* watchUnarchiveTask() {
    while (true) {
        const action: { task: TrainingTask } = yield take(Store.unarchiveTask);
        yield spawn(unarchiveTask, action.task);
    }
}

function* watchCurrentDatasetLock() {
    const datasetLockCheckDelayTimeInMs = 5 * 60 * 1000;
    while (true) {
        yield race([delay(datasetLockCheckDelayTimeInMs), take(Store.updateCurrentWorkState), take(Store.setCurrentWorkState)]);
        const currentWorkState = (yield select(selectors.getCurrentWorkState())) as WorkState | undefined;
        if (currentWorkState && currentWorkState.dataset && currentWorkState.dataset.datasetFile) {
            // re-fetch locks
            const locks = yield call(downloadDatasetLocks, currentWorkState.dataset.datasetFile.getShare());
            if (locks !== null) {
                yield put({ type: Store.setDatasetLock, datasetId: currentWorkState.dataset.getDatasetId(), scanLocks: locks, clearRequests: false });
            }
        }

    }
}

function* watchVersion() {
    yield delay(1000);
    while (true) {
        const appVersionInfo: AppVersionInfo | undefined = yield RTViewerWebClient.getAppVersionInfo();
        if (appVersionInfo) {
            yield put({ type: Store.receiveAppVersionInfo, appVersionInfo });
        }

        // wait for 1 hour before doing another version check
        yield delay(1 * 60 * 60 * 1000);
    }
}

function* watchStructureTemplates() {
    while (true) {
        yield take(Store.receiveStartFetchingStructureTemplatesType);

        const structureTemplates: StructureTemplate[] = yield rtViewerApiClient.getStructureTemplates();
        yield put({ type: Store.setStructureTemplatesType, structureTemplates });

        // the fetch can technically be forced to trigger again by calling 
        // Store.receiveStartFetchingStructureTemplatesType again -- current 
        // implementation only ever does it once, however
    }
}

function* watchBatchJobRequests() {
    while (true) {
        yield take(Store.batchJobRequestLockType);
        yield race({
            success: take(Store.batchJobRequestSuccessType),
            failure: take(Store.batchJobRequestFailureType),
        });
        yield put({ type: Store.batchJobRequestLockType, lock: false });
    }
}

function* watchBatchJobs() {
    yield take(Store.receiveStartWatchingBatchJobsType);
    while (true) {
        const batchJobs: BatchJobStatus[] = yield BatchClient.getBatchJobs();
        yield put({ type: Store.receiveBatchJobsStatusType, batchJobs });

        // either wait for 30 seconds or wait for a signal to immediately do a backend refresh
        yield race({
            interval: delay(30 * 1000),
            take: take(Store.receiveStartWatchingBatchJobsType),
        });
    }
}

export function* downloadDicomAsync(action: any) {
    // const actions: any = [];
    const request = action.request as AzureFileShareRequest;

    const fileInfo = request.fileInfo;
    const isGZip: boolean = fileInfo.filename.toLowerCase().endsWith(".gz");
    const downloadKey = action.request.downloadKey;

    const azureClient = new AzureFileClient(fileInfo);

    let file: ArrayBuffer | null = null;
    let downloadAttempt = 0;
    let downloadSucceeded = false;
    let azureDownloadError: AzureDownloadError | null = null;

    // download the dicom file, retry a couple of times in case of minor network glitches with azure
    while (!downloadSucceeded && downloadAttempt < MAX_DOWNLOAD_RETRY_ATTEMPTS) {
        downloadAttempt++;

        try {
            file = yield call(() => azureClient.downloadArrayBuffer(fileInfo));
        }
        catch (error) {
            console.error(`An error occurred when trying to download DICOM file ${fileInfo.toString()}`);
            console.log(error);
            azureDownloadError = new AzureDownloadError(fileInfo, downloadKey, error);
            continue;
        }

        downloadSucceeded = true;
    }

    if (!downloadSucceeded && azureDownloadError !== null) {
        yield put({ type: Store.receiveDownloadErrorType, error: azureDownloadError });
        return;
    }

    if (file === null || !downloadSucceeded) {
        throw new Error(`An unspecified error occurred when trying to download ${fileInfo.toString()}`);
    }

    if (isGZip) {
        try {
            file = decompressGZip(file);
        }
        catch (error) {
            console.error(error);
            console.log(`Error when trying to decompress ${fileInfo.toString()} -- will try to read it as an uncompressed DICOM file despite the file extension.`);
        }
    }

    try {

        // try to convert DICOM file into either an image slice or a structure set

        const imageSlice = dicomToImageSlice(file);
        if (imageSlice) {
            const scanId = getScanId(imageSlice, fileInfo);
            yield put({ type: Store.receiveSliceType, arrayBuffer: file, imageSlice: imageSlice, filename: fileInfo.filename, fileInfo: fileInfo });
            yield put({ type: Store.receiveFileDownloadedType, receivedFile: new ReceivedAzureFile(fileInfo, downloadKey, scanId) });
        }
        else {
            const structureSet = dicomToStructureSet(file);
            if (structureSet) {
                structureSet.scanId = getScanId(structureSet, fileInfo);
                structureSet.azureFileInfo = fileInfo;
                const filename = fileInfo.filename.toLowerCase().endsWith('.gz') ? fileInfo.filename.slice(0, -3) : fileInfo.filename;
                yield put({ type: Store.storeOriginalStructureSet, structureSetId: structureSet.structureSetId, file: file, filename: filename });
                yield put({ type: Store.receiveStructureSetType, structureSet: structureSet });
                yield put({ type: Store.receiveFileDownloadedType, receivedFile: new ReceivedAzureFile(fileInfo, downloadKey, structureSet.scanId, structureSet.structureSetId) });
            }
            else {
                const azureDownloadError = new AzureDownloadError(fileInfo, downloadKey, "Invalid or unsupported DICOM file");
                yield put({ type: Store.receiveDownloadErrorType, error: azureDownloadError });
            }
        }
    }
    catch (error) {
        console.error(error);
        console.log(`Invalid dicom file (${fileInfo.toString()})`);
    }
}


export function* downloadLiveReviewV2DicomFromMVBackendAsync(action: any) {
    const actions: any = [];
    const request = action.request as LiveReviewV2FileRequest;
    const downloadKey = request.downloadKey;
    const path = request.path;
    const liveReviewQuery = request.query;

    const liveReviewBackendClient = getRequiredLiveReviewBackendClient(liveReviewQuery);
    const liveReviewClient = new LiveReviewClient(liveReviewBackendClient);
    let arrayBuffer: ArrayBuffer | null = yield call([liveReviewClient, 'getFile'], liveReviewQuery, path);
    const filename = path.substring(path.lastIndexOf('/') + 1);

    if (arrayBuffer === null) {
        yield put({ type: Store.receiveDownloadErrorType, error: new ApiDownloadError(path, downloadKey, "Cannot download " + path) });
        return;
    }

    if (path.toLowerCase().endsWith(".gz")) {
        try {
            arrayBuffer = decompressGZip(arrayBuffer);
        }
        catch (error) {
            console.log("Error in decompression")
            yield put({ type: Store.receiveDownloadErrorType, error: new ApiDownloadError(path, downloadKey, "Cannot decompress " + path) });
            return;
        }
    }

    let imageSlice = dicomToImageSlice(arrayBuffer);
    if (imageSlice) {
        const scanId = getScanId(imageSlice);
        actions.push({ type: Store.receiveSliceType, arrayBuffer: arrayBuffer, imageSlice: imageSlice, filename: filename });
        actions.push({ type: Store.receiveFileDownloadedType, receivedFile: new ReceivedApiFile(path, downloadKey, scanId) });
    }
    else {
        let ss = dicomToStructureSet(arrayBuffer);
        if (ss) {
            ss.azureFileInfo = null;
            const filename = `structureset_${convertToFileSafe(ss.getLabel())}.dcm`;
            actions.push({ type: Store.storeOriginalStructureSet, structureSetId: ss.structureSetId, file: arrayBuffer, filename: filename });
            actions.push({ type: Store.receiveStructureSetType, structureSet: ss });
            actions.push({ type: Store.receiveFileDownloadedType, receivedFile: new ReceivedApiFile(path, downloadKey, ss.scanId, ss.structureSetId) });
        }
        else {
            actions.push({ type: Store.receiveDownloadErrorType, error: new ApiDownloadError(path, downloadKey, "Invalid DICOM file") });
        }
    }
    for (let i = 0; i < actions.length; ++i) {
        yield put(actions[i]);
    }
}

export function* uploadDicomAsync(action: any) {
    const backend = action.backend as Backend;
    const client = new ContouringClient(getBackendClient(backend));

    const scanId = action.scanId;
    const arrayBuffer = action.arrayBuffer;
    const filename = action.filename;
    const contouringAction = action.contouringAction;
    const sliceCountTotal = action.sliceCountTotal;

    const hasThisUploadAlreadyFailed: boolean = yield select(selectors.getHasUploadFailed(action.scanId));
    if (!hasThisUploadAlreadyFailed) {
        // anonymize the scan before uploading in demo environment
        const ab2 = isDemo() ? anonymizeSlice(arrayBuffer, action.dicomMapAnonReal) : arrayBuffer;

        const blob = new Blob([ab2], { type: "" });
        try {
            yield call([client, 'sendImageFile'], blob, filename, contouringAction, sliceCountTotal);
            yield put({ type: Store.receiveFileUploadedType, filename: filename, scanId: scanId });
        }
        catch (error) {
            yield put({ type: Store.receiveUploadErrorType, error: error || "Invalid DICOM file", scanId: scanId });
        }
    }
}

function* getQueryParametersFromUrl(routeLocation: Location) {
    const query = new UrlQuery(routeLocation.search);
    yield put({ type: Store.setInitialUrlQuery, urlQuery: query });
}

/** Initializes auto-loading the application into a specific state if relevant. */
function* initializeAutoload() {
    const urlQuery: UrlQuery | undefined = yield select(selectors.getUrlQuery());

    // TODO: we probably also want to check if we're in a relevant page

    // TODO: currently autoloading through sagas is supported ONLY for task work queries! Other query types
    // must go through their own component page initializers. This process should be unified at some point.
    if (urlQuery && urlQuery.isValid(UrlQueryType.TaskWorkQuery)) {

        // start by clearing existing url query from memory
        yield put({ type: Store.clearUrlQuery });

        const taskId = urlQuery.taskId!;
        yield put({ type: Store.setIsAutoloading, isAutoloading: true });

        // first, load all available tasks
        yield loadTasks();

        // see if the requested task is available
        let task: TrainingTask | undefined = yield select(selectors.getTaskById(taskId));

        // if we don't have a task, see if it's an archived one
        if (!task) {
            yield loadTasks(true);
            task = yield select(selectors.getArchivedTaskById(taskId));

            // if we still couldn't get the task, then stop here
            if (!task) {
                throw new Error(`Could not load task ${taskId}`);
            }
        }

        // set as current task and load it
        yield put({ type: Store.setCurrentTask, task: task });
        yield put({ type: Store.setCurrentWorkState, workState: new WorkState(task, false) })
        const taskDownloadId = getTaskDownloadKey(task);
        yield put({ type: Store.receiveDownloadCreated, downloadKey: taskDownloadId });
        yield put({ type: Store.requestTaskDownload, task });

        // wait for task load to finish
        let succeededOrFailed = false;
        let currentLoop = 0;
        const maxLoops = 5000;  // TODO: MAX scan size also!
        while (!succeededOrFailed) {
            currentLoop++;
            if (currentLoop > maxLoops) {
                throw new Error(`Waited over ${maxLoops} loops, terminating`);
            }

            const taskDownloadProgress = yield take([Store.receiveDownloadErrorType, Store.receiveFileDownloadedType]);
            if (taskDownloadProgress.type === Store.receiveDownloadErrorType) {
                throw new Error('Task download failed');
            }

            succeededOrFailed = yield select(selectors.isDownloadFinished(taskDownloadId));
        }

        yield openTrainingTask(task);
        yield put({ type: Store.setIsAutoloading, isAutoloading: false });

    }
}

/** Initializes application state based on deployment configuration */
function initializeDeploymentAuth(config: DeploymentConfigInfo) {
    const { rtViewerBackendTier: backendTier, rtViewerBackendUrl: backendUrl, rtViewerBackendName: backendName } = config;

    // generate new backend definition. we don't need to store this anywhere -- it's not needed after the default backend has been created and set
    const backendDefinition = new BackendDefinition(backendName || 'Default backend', backendUrl);

    // add appauth matching the backend tier to default auths if needed
    const appAuthName = getAppAuthByTier(backendTier).appName;
    if (!defaultAuths.includes(appAuthName)) {
        defaultAuths.push(appAuthName);
    }

    // set rtviewer backend
    setRTViewerBackend(new Backend(backendDefinition, backendTier));
}

function* downloadRoiGuidelines() {
    const roiGuidelines: RoiGuidelines | undefined = yield call(rtViewerApiClient.getGuidelines);
    yield put({ type: Store.downloadRoiGuidelines, roiGuidelines: roiGuidelines });
}

/** Initialize the application */
function* initialize(routeLocation: Location | undefined) {
    // // initialize deployment config
    // const configInfo: DeploymentConfigInfo = yield call([RTViewerWebClient, 'getDeploymentConfigInfo']);
    // const applicationPermissions = createApplicationPermissions(configInfo);
    // yield put({ type: Store.setDeploymentConfigInfo, deploymentConfigInfo: configInfo });
    // yield put({ type: Store.setApplicationPermissions, applicationPermissions });
    // initializeDeploymentAuth(configInfo);

    // initialize deployment config
    const configInfo: DeploymentConfigInfo = yield call([RTViewerWebClient, 'getDeploymentConfigInfo']);
    // const applicationPermissions = createApplicationPermissions(configInfo);
    yield put({ type: Store.setDeploymentConfigInfo, deploymentConfigInfo: configInfo });
    // yield put({ type: Store.setApplicationPermissions, applicationPermissions });

    const labelingInfo: LabelingInfo | undefined = yield call([RTViewerWebClient, 'getLabelingInfo']);
    yield put({ type: Store.setLabelingInfo, labelingInfo: labelingInfo });

    // get query/search parameters from url
    if (routeLocation) {
        yield getQueryParametersFromUrl(routeLocation);
    }

    yield initializeDeploymentAuth(configInfo);
    yield initializeAuth();
    yield initializeAutoload();
    yield downloadRoiGuidelines();
}

function* initializeAuth() {
    // set default auths
    let firstAuthApp: string | null = null;
    for (const authApp of defaultAuths) {
        if (!firstAuthApp) { firstAuthApp = authApp; }
        yield put({ type: Store.addAuthState, authState: new AppAuthState(authApp, true) });
    }

    // add in all the optional auths -- they're logged into later as needed
    for (const authApp of getOptionalAuths()) {
        yield put({ type: Store.addAuthState, authState: new AppAuthState(authApp, false) });
    }

    // log in the first auth app
    if (firstAuthApp) {
        const firstAuth: AppAuthState = yield select(selectors.getAuthState(firstAuthApp));
        if (!firstAuth) { throw new Error(`Could not retrieve auth app ${firstAuthApp} from store!`); }
        yield put({ type: Store.startLogin, appAuthName: firstAuth.appAuthName });
        const appAuth = getAppAuthByName(firstAuth.appAuthName);

        try {
            yield call([appAuth, 'logIn']);
        } catch (err) {
            yield put({ type: Store.setLoginError, loginError: new LoginError('message' in err && err.message === POPUPS_BLOCKED_ERROR, err.message) });

            // stop application progress here
            throw new Error('Log-in failed -- stopping application');
        }


        yield put({ type: Store.setAuthStateAsLoggedIn, appAuthName: firstAuth.appAuthName });

        // first authentication should also have authenticated current user with rtviewer backend
        const userDetails: { username: string, email: string, permissions: UserPermissions } = yield call([rtViewerApiClient, 'getAuthenticatedUser']);
        const loggedInUser = appAuth.getLoggedInUserDetails();
        yield put({ type: Store.setUserDetails, ...userDetails, loggedInUser });
    }

    // do rest of the required logins one by one
    yield authLoginLogoutOnRequest(false);

    // do we have a login error? if so, stop here
    const wasLoginSuccessful: boolean = yield select(selectors.getWasLoginSuccessful());
    if (!wasLoginSuccessful) {
        console.error('Login has failed:');
        console.log(yield select(selectors.getLoginError()));
        return;
    }

    // wait for any new login/logout requests
    yield spawn(authLoginLogoutOnRequest, true);
}

function* authLoginLogoutOnRequest(loopForever: boolean) {
    const authsNeedingLogin: AppAuthState[] = [];
    const authsNeedingLogout: AppAuthState[] = [];

    let keepLooping = true;

    do {
        // begin by checking we haven't missed any auths that need login;
        authsNeedingLogin.push(...(yield select(selectors.getAuthStatesThatNeedLogin())) as AppAuthState[]);

        // start logging into app auths one by one
        while (authsNeedingLogin.length > 0) {
            const authState = authsNeedingLogin.pop() as AppAuthState;
            yield put({ type: Store.startLogin, appAuthName: authState.appAuthName });
            const appAuth = getAppAuthByName(authState.appAuthName);

            try {
                yield call([appAuth, 'logIn']);
            } catch (err) {
                yield put({ type: Store.setLoginError, loginError: new LoginError('message' in err && err.message === POPUPS_BLOCKED_ERROR, err.message) });

                // stop application progress here
                throw new Error('Log-in failed -- stopping application');
            }

            yield put({ type: Store.setAuthStateAsLoggedIn, appAuthName: authState.appAuthName });
        }

        // start logging out
        while (authsNeedingLogout.length > 0) {
            const authState = authsNeedingLogout.pop() as AppAuthState;
            yield put({ type: Store.startLogout, appAuthName: authState.appAuthName });
            const appAuth = getAppAuthByName(authState.appAuthName);

            try {
                yield call([appAuth, 'logOut']);
            } catch (err) {
                yield put({ type: Store.setLoginError, loginError: new LoginError('message' in err && err.message === POPUPS_BLOCKED_ERROR, err.message) });

                // stop application progress here
                throw new Error('Log-out failed -- stopping application');
            }

            // a successful logout will automatically redirect the user somewhere else
        }

        // stop the loop here if this isn't supposed to be an infinite loop
        if (!loopForever) {
            break;
        }

        // wait for a new login request by waiting for an existing app auth state to be set as required
        const loginOrLogoutRequest: { appAuthName: string, type: string } = yield take([Store.setAuthStateAsRequired, Store.requestLogOut]);
        if (loginOrLogoutRequest.type === Store.setAuthStateAsRequired) {
            const appAuthState: AppAuthState = yield select(selectors.getAuthState(loginOrLogoutRequest.appAuthName));
            if (appAuthState.logInProcessState === LogInProcessState.NotLoggedIn) {
                authsNeedingLogin.push(appAuthState);
            }
        } else if (loginOrLogoutRequest.type === Store.requestLogOut) {
            // log out of the (first) default app auth -- we only ever allow one user to log in at once so that's enough
            // to log out of everything
            const appAuthState: AppAuthState = yield select(selectors.getAuthState(defaultAuths[0]));
            authsNeedingLogout.push(appAuthState);
        }
    } while (keepLooping);
}

function* waitForLoginsToFinish() {
    let isWaitingForLoginsToFinish: boolean = yield select(selectors.getAuthStatesAreLoginsNeeded());
    while (isWaitingForLoginsToFinish) {
        yield take(Store.setAuthStateAsLoggedIn);
        isWaitingForLoginsToFinish = yield select(selectors.getAuthStatesAreLoginsNeeded());
    }
}

function* addNotification(message: string, notificationType: NotificationType, detailedMessage?: string) {
    const id = `notification-${notificationType.toString()}-${Date.now().toString()}`;
    yield put({ type: Store.addNotificationType, notification: new SessionNotification(id, message, notificationType, detailedMessage) });
}

// save structure set 
/* 
function* saveStructureSet(structureSet: StructureSet, image: Image, datasetImg: DatasetImage) {
        const vs = new ViewerState(image, structureSet, structureSet.dataset, datasetImg, true, true);
        const ssListAll = vs.allStructureSets;
        if(!ssListAll) return;
        const ssListUnsaved = ssListAll.filter(ss => ss.unsaved && (!ss.isOriginal || !ss.existsInAzure));
        if (ssListUnsaved.length === 0) { return; }

        // get structure set with same sop id as the one in the this.props.currentTask
        const ss = structureSet;

        // Recreate CSV representation objects for structure sets
        const datasetImage = ss!.dataset ? ss!.dataset : null;
        if (datasetImage) {
            const dataset: Dataset = structureSet.dataset;
            const datasetStructureSets = datasetImage.structureSets;
            datasetImage.structureSets = [];
            for (let i = 0; i < ssListAll.length; ++i) {

                const ss = ssListAll[i];
                if (!ss.deleted) {
                    const existingMatches = datasetStructureSets.filter((dss: DatasetStructureSet) => dss.sopId === ss.structureSetId);
                    const match = existingMatches.length ? existingMatches[0] : null;

                    // Create roi mappings
                    const oldMappings = match ? match.roiMappings : [];
                    const newMappings: datasetFiles.RoiMapping[] = [];
                    ss.getRois().forEach((roi) => {
                        const allowedNames = dataset.metaFiles.allAllowedRoiNames;
                        if (allowedNames && allowedNames.includes(roi.name)) {
                            newMappings.push(new datasetFiles.RoiMapping(roi.name, roi.name));
                        } else {
                            let matchFound = false;
                            oldMappings.forEach((mpp: datasetFiles.RoiMapping) => {
                                if (roi.name === mpp.originalName) {
                                    if (!matchFound) {
                                        newMappings.push(mpp);
                                        matchFound = true;
                                    }
                                }
                            });
                            if (!matchFound) {
                                newMappings.push(new datasetFiles.RoiMapping(roi.name, ""));
                            }
                        }
                    });

                    const bestMatch = Boolean(match && match.bestMatch);
                    const ds = new DatasetStructureSet(dataset.getDatasetId(), datasetImage.seriesId, ss.seriesUid, ss.structureSetId, ss.scanId, ss.frameOfReferenceUid,ss.dataset.ApprovalStatus, ss.dataset.StructureSetLabel, bestMatch, newMappings);
                    datasetImage.structureSets.push(ds);
                }
            }

            let lockId: any = "";
            document.body.style.cursor = 'wait';

            ssListUnsaved.forEach(ss => {
                ss.modalMessage = structureSet.StructureSetModalMessages.Saving;
            });
            vs.notifyListeners();
            yield sleep(200);

            const promises: any = [];

            const finished = (success: boolean) => {
                document.body.style.cursor = 'default';
                ssListUnsaved.forEach(ss => {
                    ss.modalMessage = null;
                });
                if (success) {
                    vs.undoStack.clear();
                }
                vs.notifyListeners();
            }

            // TODO: move this out of RTViewer
            if (datasetImage) {
                Promise.all(promises)
                    .then(() => datasetFiles.enterCSVMutex(dataset))
                    .then((id) => {
                        lockId = id
                    })
                    .then(() => datasetFiles.saveDatasetImage(datasetImage, dataset, _.get(this.state, 'viewerState.username', undefined)))
                    .then(() => datasetFiles.setStructureSetsEdited(datasetImage, dataset, ssListUnsaved.map(ss => ss.structureSetId), vs.username))
                    .then(() => {
                        datasetFiles.leaveCSVMutex(dataset, lockId);
                        lockId = null;
                    })
                    .then(() => {
                        finished(true);
                    })
                    .catch(function (error) {
                        finished(false);
                        console.log(error);
                        if (lockId) datasetFiles.leaveCSVMutex(dataset, lockId);
                        alert("Failure in saving! Check console for details (F12)");
                    });
            } else {
                Promise.all(promises)
                    .then(() => {
                        finished(true);
                    })
                    .catch(function (error) {
                        finished(false);
                        console.log(error);
                        alert("Failure in saving! Check console for details (F12)");
                    });
            }
        }
} */

function* requestDatasetLock(datasetImage: DatasetImage, dataset: Dataset, lockAction: LockAction) {
    // start a dataset lock request
    // TODO: locks may need to be changed to work via scanIds instead of seriesIds
    const datasetId = dataset.getDatasetId();
    const seriesId = datasetImage.seriesId;
    yield put({ type: Store.startDatasetLockRequest, datasetId: datasetId, seriesId: seriesId, lockAction: lockAction });

    // try to get a scan lock to the specific scan in the dataset
    let mutexId: string | null = null;
    let locks: ScanLocks | null = null;
    try {
        const user: User = yield select(selectors.getUser());
        const username = user.username;

        mutexId = yield call([datasetFiles, 'enterCSVMutex'], dataset);
        locks = yield call([datasetFiles, 'setDatasetLock'], dataset, seriesId, username, lockAction);
    }
    catch (error) {
        // current error handling is to just silently remove the scan lock request
        console.log(error);
        yield put({ type: Store.undoDatasetLockRequest, datasetId: datasetId, seriesId: seriesId });
    }
    finally {
        // remove csv mutex lock if it was taken
        if (mutexId !== null) { yield call([datasetFiles, 'leaveCSVMutex'], dataset, mutexId); }
    }

    // if successful, finish the request
    if (locks !== null) {
        yield put({ type: Store.setDatasetLock, datasetId: datasetId, scanLocks: locks, clearRequests: true });
    }
}

function* requestStructureSetLock(rtStructLock: RTStructLock, lockAction: LockAction) {

    // start a structure set lock request   
    yield put({ type: Store.startStructureSetLockRequest, structureSetId: rtStructLock.sopInstanceUid, lockAction: lockAction });

    let succeeded = false;

    if (lockAction === LockAction.Lock) {
        succeeded = yield call(() => rtViewerApiClient.lockRTStruct(rtStructLock));
    } else if (lockAction === LockAction.Unlock) {
        succeeded = yield call(() => rtViewerApiClient.unlockRTStruct(rtStructLock));
    } else {
        throw new Error('Unsupported lock request!');
    }

    if (!succeeded) {
        // the item is locked by someone else -- show a notification
        const message = `Could not ${lockAction === LockAction.Lock ? 'lock' : 'unlock'} this structure set -- someone else has locked it.`;
        yield put({
            type: Store.addNotificationType,
            notification: new SessionNotification(`rtstructed-locked-${rtStructLock.sopInstanceUid}-${Date.now().toString()}`, message, NotificationType.Warning)
        });
    }

    // reload structure set locks, clear this request afterwards
    yield put({ type: Store.getStructureSetLocks, clearRequest: rtStructLock.sopInstanceUid });
}

/** Download tasks and replace with latest from the backend */
function* loadTasks(getArchivedTasks: boolean = false) {
    try {
        if (!getArchivedTasks) {
            const tasks: TrainingTasks = yield call(downloadTasks);
            yield put({ type: Store.downloadTasks, tasks: tasks });
        } else {
            const archivedTasks: TrainingTasks = yield call(downloadArchivedTasks);
            yield put({ type: Store.downloadArchivedTasks, tasks: archivedTasks });
        }
    } catch (err) {
        console.error('An error occurred when trying to load tasks:');
        console.error(err);
    }
}

function* updateTask(task: TrainingTask, update: Partial<TrainingTask>, noSuccessNotification: boolean) {

    // check if we're finishing this task -- if so, make sure this wasn't already finished before
    // TODO: this code is currently irrelevant as canFinish is already being checked in DifferencesToolbar
    // and in canUserFinishTask before saving, but this would be the way to go in the future.
    // if (_.get(update, 'state', undefined) === 'FINISHED') {
    //     canUpdate = false;

    //     if (task.type === 'PRACTICE') {
    //         // practice tasks can always be updated
    //         canUpdate = true;
    //     } else {
    //         const user: User = yield select(selectors.getUser());
    //         if (user.permissions.isSupervisor) { 
    //             // supervisors can always update tasks
    //             canUpdate = true; 
    //         } else if (user.permissions.isTrainee && task.trainee.user_name === user.username) {
    //             canUpdate = true;

    //             const matchingTask: Task | undefined = yield call(rtViewerApiClient.getTask, task.id);
    //             if (matchingTask && (matchingTask.state === 'FINISHED' || matchingTask.state === 'GRADED')) {
    //                 // trainees can't update non-practice tasks that have already been finished
    //                 canUpdate = false;
    //             }
    //         }
    //     }


    let notification: SessionNotification | undefined = undefined;
    let updatedSucceeded = true;

    try {
        const updatedTask: TrainingTask = { ...task, ...update };
        yield put({ type: Store.startUpdatingTask, task: updatedTask });
        yield call(rtViewerApiClient.updateTask, updatedTask);
        if (!noSuccessNotification) {
            notification = new SessionNotification(`task-updated-${Date.now().toString()}`, 'Task was updated.', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS);
        }
        yield call(loadTasks);

        // swap currentTask (if any) to use updated data
        // TODO: inform/warn user if the task has unexpected changes
        const currentTask: TrainingTask | undefined = yield select(selectors.getCurrentTask());
        if (currentTask) {
            const matchingTask: TrainingTask | undefined = yield select(selectors.getTaskById(currentTask.id));
            if (matchingTask) {
                // TODO: in some cases it may be problematic to change the current task, but for now this should be safe
                yield put({ type: Store.setCurrentTask, task: matchingTask });
            } else {
                throw new Error(`Could no longer find task ${currentTask.id} after task update`);
            }
        }
    }
    catch (err) {
        updatedSucceeded = false;
        const detailedMessage = _.isError(err) ? err.message : undefined;
        notification = new SessionNotification(`task-updated-${Date.now().toString()}`, 'An error occurred while updating task.', NotificationType.Error, detailedMessage);
    }
    finally {
        yield put({ type: Store.finishUpdatingTask, updatedSucceeded });
    }

    if (notification) {
        yield put({ type: Store.addNotificationType, notification })
    }
}

function* startTask(task: TrainingTask) {
    let canUpdate = true;
    const azureShare = AzureShareInfo.fromTask(task);

    if (canUpdate && azureShare) {
        yield put({ type: Store.startUpdatingTask, task: task });

        yield call(rtViewerApiClient.startTask, task);

        yield call(loadTasks);

        // swap currentTask (if any) to use updated data
        // TODO: inform/warn user if the task has unexpected changes
        const currentTask: TrainingTask | undefined = yield select(selectors.getCurrentTask());
        if (currentTask) {
            const matchingTask: TrainingTask | undefined = yield select(selectors.getTaskById(currentTask.id));
            if (matchingTask) {
                yield put({ type: Store.setCurrentTask, task: matchingTask });
            } else {
                throw new Error(`Could no longer find task ${currentTask.id} after task update`);
            }
        }

        yield put({ type: Store.finishUpdatingTask });
    }
    //}
}


function* finishTask(task: TrainingTask, image: Image, roiNameMaps: RoiNameMap[]) {
    let canUpdate = true;

    // check if we're finishing this task -- if so, make sure this wasn't already finished before
    // TODO: this code is currently irrelevant as canFinish is already being checked in DifferencesToolbar
    // and in canUserFinishTask before saving, but this would be the way to go in the future.

    /*    console.log("task state: " + task.state)
        if (_.get(task, 'state', undefined) === 'FINISHED') {
            canUpdate = false;
        console.log("task state: " + task.state)
    
    
            if (task.type === 'PRACTICE') {
                // practice tasks can always be updated
                canUpdate = true;
            } else {
                const user: User = yield select(selectors.getUser());
                if (user.permissions.isSupervisor) {
                    // supervisors can always update tasks
                    canUpdate = true;
                } else if (user.permissions.isTrainee && task.trainee.user_name === user.username) {
                    canUpdate = true;
    
                    const matchingTask: TrainingTask | undefined = yield call(rtViewerApiClient.getTask, task.id);
                    if (matchingTask && (matchingTask.state === 'FINISHED' || matchingTask.state === 'GRADED')) {
                        // trainees can't update non-practice tasks that have already been finished
                        canUpdate = false;
                    }
                }
            } */
    if (canUpdate) {
        yield put({ type: Store.startUpdatingTask, task: task });

        yield call(rtViewerApiClient.finishTask, task, image, roiNameMaps);

        yield put({
            type: Store.addNotificationType,
            notification: new SessionNotification(`task-${task.id}-state-changed-${Date.now()}`, 'Task Finished', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS)
        });

        yield call(loadTasks);

        // swap currentTask (if any) to use updated data
        // TODO: inform/warn user if the task has unexpected changes
        const currentTask: TrainingTask | undefined = yield select(selectors.getCurrentTask());
        if (currentTask) {
            const matchingTask: TrainingTask | undefined = yield select(selectors.getTaskById(currentTask.id));
            if (matchingTask) {
                yield put({ type: Store.setCurrentTask, task: matchingTask });
            } else {
                throw new Error(`Could no longer find task ${currentTask.id} after task update`);
            }
        }

        yield put({ type: Store.finishUpdatingTask });
    }
    //}
}

function* deleteTask(task: TrainingTask) {
    yield put({ type: Store.startUpdatingTask, task: task });
    let notification: SessionNotification | undefined = undefined;

    try {
        yield call(rtViewerApiClient.deleteTask, task);
        notification = new SessionNotification(`task-deleted-${Date.now().toString()}`, 'Task was deleted.', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS);
        yield call(loadTasks);
    }
    catch (err) {
        const detailedMessage = _.isError(err) ? err.message : undefined;
        notification = new SessionNotification(`task-deleted-${Date.now().toString()}`, 'An error occurred while deleting task.', NotificationType.Error, detailedMessage);
    }
    finally {
        yield put({ type: Store.finishUpdatingTask });
    }

    if (notification) {
        yield put({ type: Store.addNotificationType, notification })
    }
}

function* archiveTask(task: TrainingTask) {
    yield put({ type: Store.startUpdatingTask, task: task });
    let notification: SessionNotification | undefined = undefined;
    let updatedSucceeded = true;

    try {
        yield call(rtViewerApiClient.archiveTask, task);
        notification = new SessionNotification(`task-archived-${Date.now().toString()}`, 'Task was archived.', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS);
        yield call(loadTasks);
    }
    catch (err) {
        updatedSucceeded = false;
        const detailedMessage = _.isError(err) ? err.message : undefined;
        notification = new SessionNotification(`task-archived-${Date.now().toString()}`, 'An error occurred while archiving task.', NotificationType.Error, detailedMessage);
    }
    finally {
        yield put({ type: Store.finishUpdatingTask, updatedSucceeded });
    }

    if (notification) {
        yield put({ type: Store.addNotificationType, notification })
    }
}

function* unarchiveTask(task: TrainingTask) {
    yield put({ type: Store.startUpdatingTask, task: task });
    let notification: SessionNotification | undefined = undefined;
    let updatedSucceeded = true;

    try {
        yield call(rtViewerApiClient.unarchiveTask, task);
        notification = new SessionNotification(`task-unarchived-${Date.now().toString()}`, 'Task was unarchived.', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS);
        yield call(loadTasks);
    }
    catch (err) {
        updatedSucceeded = false;
        const detailedMessage = _.isError(err) ? err.message : undefined;
        notification = new SessionNotification(`task-unarchived-${Date.now().toString()}`, 'An error occurred while unarchiving task.', NotificationType.Error, detailedMessage);
    }
    finally {
        yield put({ type: Store.finishUpdatingTask, updatedSucceeded });
    }

    if (notification) {
        yield put({ type: Store.addNotificationType, notification })
    }
}

function* openTrainingTask(task: TrainingTask | undefined) {
    if (!task) {
        throw new Error('Closing of task is not yet implemented!')
    }

    yield put({ type: Store.setCurrentTask, task: task });

    // set state of task to "STARTED" if it is not already
    // TODO: move this into a more sensible place
    const user: User | undefined = yield select(selectors.getUser());
    if (!user) { throw new Error('User is not valid'); }
    if (user.isTaskTrainee(task) && task.state === TrainingTaskState.NotStarted) {
        yield put({ type: Store.startTask, task });
    }

    const structureSetLocks: RTStructLockDictionary | undefined = yield select(selectors.getStructureSetLocks());
    if (!structureSetLocks) { throw new Error('Structure set locks are not valid'); }
    const canEdit = getUserCanEditTask(user, task, structureSetLocks);

    const workState = new WorkState(task, canEdit);
    yield put({ type: Store.setCurrentWorkState, workState: workState });
    yield put({ type: Store.setAutoOpenTaskItem, autoOpenTaskItem: task.id });
}

function* loadDataset(azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string) {

    if (!datasetId) {
        throw new Error(`Invalid Dataset ID: must not be falsy ('${datasetId}').`);
    }

    // mark download as started
    yield put({ type: Store.startDatasetDownload, datasetId: datasetId });
    //yield put({ type: Store.setCurrentTask, task: })

    // download the dataset
    try {
        // add or replace dataset in store, mark download as finished
        const downloadInfoFile = function* () {
            const dataset: Dataset = yield call(downloadDatasetInfo, azureShare, reloadMetaFiles, datasetId);
            yield put({ type: Store.addDataset, dataset: dataset, overrideGradings: reloadMetaFiles });
        }
        // download users for supervisor view 
        const downloadUsersFile = function* () {
            const currentUsers: TrainingUser[] = yield call(rtViewerApiClient.getAvailableUsers);
            yield put({ type: Store.downloadUsers, users: currentUsers });
        }

        // download and replace current dataset locks info from latest from azure
        const downloadLockFile = function* () {
            const datasetLocks: ScanLocks = yield call(downloadDatasetLocks, azureShare);
            yield put({ type: Store.setDatasetLock, datasetId: datasetId, scanLocks: datasetLocks, clearRequests: true });
        }

        // download all patients for current azure share
        const downloadPatients = function* () {
            const patients: any[] = yield call(rtViewerApiClient.getAvailablePatients, azureShare.storageAccountName, azureShare.fileShareName);
            yield put({ type: Store.downloadPatients, patients: patients });
        }

        const downloadRoisPerFileShare = function* () {
            const roisPerFileShare: any[] = yield call(rtViewerApiClient.getAvailableGtRois, azureShare.storageAccountName, azureShare.fileShareName);
            yield put({ type: Store.downloadGTRois, gtRois: roisPerFileShare });
        }


        // run these serially for now or otherwise we run into problems when we have expired auth tokens
        yield call(downloadInfoFile);
        yield call(downloadLockFile);
        yield call(downloadUsersFile);
        yield call(downloadPatients);
        yield call(downloadRoisPerFileShare);
        // yield all([call(downloadInfoFile), call(downloadLockFile), call(downloadUsersFile), call(downloadPatients), call(downloadRoiGuidelines), call(downloadRoisPerFileShare)]);
    }
    catch (err) {
        console.error(err);
        throw err;
    }
    finally {
        yield put({ type: Store.finishDatasetDownload, datasetId: datasetId });
    }
}

// TODO: collect common parts of all the download functions into one generic one
function* downloadTask(task: TrainingTask) {
    const downloadKey = getTaskDownloadKey(task);

    const shareInfo = new AzureShareInfo(task.storageAccount, task.fileShare);
    const scanPathSegments = task.azurePath.split('/');
    if (scanPathSegments.length <= 2) {
        throw new Error(`Invalid scan file path (${task.azurePath})`);
    }

    // series path is azurePath without its first two and the last segment
    const seriesPath = scanPathSegments.filter((s, i) => i > 1 && i < scanPathSegments.length - 1).join('/');
    const scanPath = new AzurePathInfo(shareInfo, `${seriesPath}/${scanPathSegments[scanPathSegments.length - 1]}`);
    const testRtStructFile = new AzureFileInfo(shareInfo, `${seriesPath}/RTSTRUCT-${task.traineeStructureSet.seriesInstanceUid}`, `${task.traineeStructureSet.sopInstanceUid}`);
    const referenceRtStructFile = new AzureFileInfo(shareInfo, `${seriesPath}/RTSTRUCT-${task.gtStructureSet.seriesInstanceUid}`, `${task.gtStructureSet.sopInstanceUid}`);

    const azureFileClient = new AzureFileClient(shareInfo);

    try {
        // step 1 -- initialize downloads of image slices

        const imageFiles: AzureFileInfo[] = yield call(() => azureFileClient.listFiles(scanPath));
        for (const imageFile of imageFiles) {
            const fileInfo = scanPath.getFile(imageFile.filename);
            try {
                const lower = imageFile.filename.toLowerCase();
                if (lower.endsWith(".dcm") || lower.endsWith(".gz")) {
                    const request = new AzureFileRequest(fileInfo, downloadKey);
                    yield put({ type: Store.receiveDownloadAddFile, request: request });
                }
            }
            catch (error) {
                console.error(`Error when trying to download image file ${fileInfo.toString()}`);
                console.log(error);
                yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(fileInfo, downloadKey, error) });
            }
        }

        // step 2 -- initialize downloads of structure sets
        for (const structureSetFile of [testRtStructFile, referenceRtStructFile]) {
            try {
                const fileInfo = yield call(() => azureFileClient.findFileWithExtension(structureSetFile, ['.dcm.gz', '.dcm', '']));
                if (fileInfo) {
                    const request = new AzureFileRequest(fileInfo, downloadKey);
                    yield put({ type: Store.receiveDownloadAddFile, request: request });
                } else {
                    throw new Error(`Could not find file ${structureSetFile.filename} for task ${task.id}`);
                }
            }
            catch (error) {
                console.error(`Error when trying to download structure set file ${structureSetFile.toString()}`);
                console.log(error);
                yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(structureSetFile, downloadKey, error) });
            }

        }
    }
    catch (error) {
        console.error(`Error when trying to download image and structure set files from ${shareInfo.toString()} for download key "${downloadKey}"`);
        console.log(error);
        yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(shareInfo, downloadKey, error) });
    }
}

function* downloadImageAndStructureSet(datasetImage: DatasetImage, dataset: Dataset, reloadDataset: boolean = false) {
    const datasetId = dataset.getDatasetId();
    let updatedDataset: Dataset | undefined = dataset;

    // first, reload the dataset if so requested
    if (reloadDataset) {
        // just always reload meta files, the performance hit is not significant
        const reloadMetafiles = true;
        // const existingDataset: Dataset | undefined = yield select(getDataset(datasetId));
        // const reloadMetafiles = existingDataset === undefined;

        yield loadDataset(dataset.datasetFile.getShare(), reloadMetafiles, datasetId);

        updatedDataset = yield select(selectors.getDataset(datasetId));
        if (updatedDataset === undefined) {
            throw new Error(`Was not able to properly reload dataset ${datasetId}`);
        }
    }

    const downloadKey = datasetImage.downloadKey;
    const shareInfo = updatedDataset.datasetFile.getShare();
    const azureFileClient = new AzureFileClient(shareInfo);

    try {
        // step 1 -- initialize downloads of image slices
        const imageFiles: AzureFileInfo[] = yield call(() => azureFileClient.listFiles(shareInfo, datasetImage.path));
        for (const imageFile of imageFiles) {
            const fileInfo = shareInfo.getFile(datasetImage.path, imageFile.filename);
            try {
                const lower = imageFile.filename.toLowerCase();
                if (lower.endsWith(".dcm") || lower.endsWith(".gz")) {
                    const request = new AzureFileRequest(fileInfo, downloadKey);
                    yield put({ type: Store.receiveDownloadAddFile, request: request });
                }
            }
            catch (error) {
                console.error(`Error when trying to download image file ${fileInfo.toString()}`);
                console.log(error);
                yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(fileInfo, downloadKey, error) });
            }
        }

        // step 2 -- initialize downloads of structure sets
        for (let s = 0; s < datasetImage.structureSets.length; ++s) {
            const structureSet = datasetImage.structureSets[s];

            // get a "best guess file" that's about in the correct place
            const bestGuessFile = datasetFiles.getStructureSetFileInfo(shareInfo, datasetImage.patientId, datasetImage.frameOfReferenceUid,
                structureSet.seriesId, structureSet.sopId);

            // now get the actual file(s) from the same folder
            const structureSetFiles: AzureFileInfo[] = yield call(() => azureFileClient.listFiles(bestGuessFile.getPath()));
            for (const structureSetFile of structureSetFiles) {
                try {
                    const lower = structureSetFile.filename.toLowerCase();
                    if (lower.endsWith(".gz") || lower.endsWith(".dcm")) {
                        const request = new AzureFileRequest(structureSetFile, downloadKey);
                        yield put({ type: Store.receiveDownloadAddFile, request: request });
                    }
                }
                catch (error) {
                    console.error(`Error when trying to download structure set file ${structureSetFile.toString()}`);
                    console.log(error);
                    yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(structureSetFile, downloadKey, error) });
                }
            }

        }
    }
    catch (error) {
        console.error(`Error when trying to download image and structure set files from ${shareInfo.toString()} for download key "${downloadKey}"`);
        console.log(error);
        yield put({ type: Store.receiveDownloadErrorType, error: new AzureDownloadError(shareInfo, downloadKey, error) });
    }
}

function* getStructureSetLocks(clearRequestId: string | undefined) {
    const locks: RTStructLock[] = yield call(() => rtViewerApiClient.getLockedRtstructs());
    const lockDictionary: RTStructLockDictionary = locks.reduce((acc, current) => { acc[current.sopInstanceUid] = current; return acc; }, {} as RTStructLockDictionary);
    yield put({ type: Store.setStructureSetLocks, structureSetLocks: lockDictionary, clearRequestId });
}

/* // download tasks and replace with lastest from the backend
function* downloadTasksFile(storageAccount: string, fileShare: string) {
    const datasetCurrentTasks: Tasks = yield call(downloadTasks, storageAccount, fileShare);
    console.log(`Downloaded ${datasetCurrentTasks.length} tasks.`);
    console.log(datasetCurrentTasks);
    yield put({ type: Store.downloadTasks, tasks: datasetCurrentTasks });
}
 */
/**
 * Download annotation items specified in the given annotation query object.
 */
function* downloadAnnotationQueryItems(annotationQuery: UrlQuery, workspace: Workspace) {
    let datasetId: string | undefined;
    let dataset: Dataset | undefined;
    let datasetImage: DatasetImage | null = null;

    // load up dataset & scan if they've been defined
    if (annotationQuery.isValid(UrlQueryType.AnnotationWorkQuery)) {
        try {
            // 1. Load the dataset
            const azureShare = new AzureShareInfo(annotationQuery.storageAccount!, annotationQuery.fileShare!);
            datasetId = Dataset.generateDatasetId(azureShare);
            yield loadDataset(azureShare, true, datasetId);

            // 2. Load image and structure sets
            dataset = yield select(selectors.getDataset(datasetId));
            if (!dataset) {
                throw new Error(`Unable to load dataset ${datasetId}`);
            }
            const scanId = getScanId(annotationQuery);
            datasetImage = dataset.getImage(scanId);
            if (!datasetImage) {
                throw new Error(`Did not find scan "${scanId}" in dataset ${datasetId}`);
            }
            yield downloadImageAndStructureSet(datasetImage, dataset, false);
        }
        catch (err) {
            // Set current work state into an error state. Also display a notification message.
            const errorTitle = 'An error occurred when trying to load dataset or image';
            const errorMessage = err.message || undefined;
            console.log(`${errorTitle}:`)
            console.error(err);
            yield put({ type: Store.setCurrentWorkState, workState: new WorkState(null, null, false, workspace, false, null, errorMessage) });
            yield addNotification(`${errorTitle}.`, NotificationType.Error, errorMessage);
            throw err;
        }

        // 3. Set the current workstate
        const user: User = yield select(selectors.getUser());
        const locks: ScanLocks = yield select(selectors.getLocksForDataset(datasetId));
        const canEdit = datasetFiles.getUserCanEdit(user, locks, datasetImage.seriesId);
        const canCreateRtstruct = datasetFiles.getUserCanCreateRtstruct(user, locks, datasetImage.seriesId);
        yield put({ type: Store.setCurrentWorkState, workState: new WorkState(datasetImage, dataset, canEdit, workspace, canCreateRtstruct, annotationQuery.defaultStructureSetUid || null) });
    }
    else if (annotationQuery.isValid(UrlQueryType.AnnotationIndexQuery) || annotationQuery.isValid(UrlQueryType.ReferenceLibraryIndexQuery)) {
        // Immediately set current work state to a dataset index work state to avoid unnecessarily showing a splash screen when we don't want it
        yield put({ type: Store.setCurrentWorkState, workState: new WorkState(annotationQuery.storageAccount!, annotationQuery.fileShare || null, null, workspace) });

        // Load the dataset if fileshare has been defined (otherwise we just load the available file shares on the annotation page/dataset browser component)
        if (annotationQuery.fileShare !== undefined) {
            const azureShare = new AzureShareInfo(annotationQuery.storageAccount!, annotationQuery.fileShare);
            datasetId = Dataset.generateDatasetId(azureShare);
            yield loadDataset(azureShare, true, datasetId);
            dataset = yield select(selectors.getDataset(datasetId));
            yield put({ type: Store.setCurrentWorkState, workState: new WorkState(annotationQuery.storageAccount!, annotationQuery.fileShare || null, dataset || null, workspace) });
        }

    } else {
        throw new Error('Invalid annotation query item');
    }
}

/**
 * Save grading sheet of a single structure set into a file into azure.
 */
function* saveStructureSetGradingToFile(structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) {
    const gradingToSave: datasetFiles.GradingToSave = { ssId: structureSetId, ssGrading };
    yield startSavingDatasetGradingsToFile([gradingToSave], dataset);
}

/**
 * Start saving dataset gradings into a physical file (i.e. azure). In practice this function will
 * first apply local modifications and then call the rest of the save functionality through a
 * debounced store action.
 * @param gradingsToSave Array of latest modifications to gradings.
 * @param dataset Dataset to apply these modifications for.
 */
function* startSavingDatasetGradingsToFile(gradingsToSave: datasetFiles.GradingToSave[], dataset: Dataset) {
    // 1. Signal that we're starting a save operation & keep track of what we've modified
    yield put({ type: Store.startDatasetGradingSave, gradingsToSave, dataset });

    // 2. Immediate state update -- set new gradings into store until we've synced the real state from azure
    yield put({ type: Store.setStructureSetGrading, gradingsToSave, dataset });

    // 3. Do the actual save operation, but with a debounce so we don't spam it unnecessarily
    yield put({ type: Store.debouncedFinishDatasetGradingSave, gradingsToSave, dataset });
}

/**
 * Finish saving dataset gradings to azure disk. The actual save operation to cloud is performed 
 * here.
 * @param action Redux action object. Must contain a type (for redux/saga to work) and a
 * reference to the dataset we're operating on.
 */
function* finishSavingDatasetGradingsToFile(action: { type: any, dataset: Dataset }) {
    const { dataset } = action;
    let mutexId: string | null = null;
    let wasSuccessful = false;
    let gradingsToSave: GradingToSave[] = [];
    try {
        const user: User = yield select(selectors.getUser());
        mutexId = yield call(datasetFiles.enterCSVMutex, dataset);

        // get all current local modifications to gradings at once
        gradingsToSave = yield select(selectors.getModifiedDatasetGradings(dataset.getDatasetId()));

        // possibly nothing to save here
        if (gradingsToSave.length !== 0) {
            // save the new gradings to the file share and return the latest dataset grading file with our new modifications in it
            const syncedNewGradings: DatasetGradings = yield call(datasetFiles.saveGradings, dataset, gradingsToSave, user.username);

            // create a backup of the new dataset gradings we just created
            yield call(backupGradings, syncedNewGradings, dataset.datasetFile.getShare(), user.username);
        }

        wasSuccessful = true;

        // Right here we could then put the new, synced dataset gradings to store by calling yield put for Store.setDatasetGradings.
        // However, doing so can cause weird interactions in UI due to the asynchronous nature of these saving operations
        // and we'd need to do reconciliation between incoming and local, potentially at this point many times modified data.
        // So instead we're ignoring the up-to-date state of the returned dataset grading sheet.
        //
        // This has following repercussions:
        //
        // 1. We will now use local grading sheet data for the current scan as its single source of truth.
        //    This is ok as a) we keep track of all local changes, b) user needs a lock to edit grading sheets
        //    so no one else can modify the gradings of the same scan simultaneously, c) all changes are
        //    eventually saved to the dataset gradings file.
        //
        // 2. The local copy of dataset gradings is no now longer in sync with the data in the file share. This is ok
        //    as a) changing to a different scan will cause dataset gradings to get refreshed, b) we never save
        //    the entire dataset grading file using local cached data, the current up-to-date dataset gradings
        //    data is always fetched first from the file share, it just doesn't get updated to the redux store.
        //
        // TODO: in future we may want to consider doing reconciliation here instead of having to reload all gradings
        // on all possible context changes.
    }
    catch (error) {
        wasSuccessful = false;
        console.log(error);
        alert("Error saving gradings. See console for details (F12)");
    }
    finally {
        if (mutexId !== null) { yield call(datasetFiles.leaveCSVMutex, dataset, mutexId); }
        yield put({ type: Store.finishDatasetGradingSave, wasSuccessful, dataset, gradingsToSave });
    }
}
/**
 * Reloads and replaces the entire dataset gradings for the specified dataset.
 */
function* reloadDatasetGradings(dataset: Dataset) {
    const gradings: DatasetGradings = yield call(downloadGradings, dataset.datasetFile.getShare());
    yield put({ type: Store.setDatasetGradings, datasetId: dataset.getDatasetId(), datasetGradings: gradings });
}

/** Saves a scan from memory to local disk. */
function* saveScanToUserDevice(scanId: string, zipFilename: string, anonymizeScan: boolean) {
    const scan = yield select(selectors.getScan(scanId));
    const anonymizationMap = new DicomMapAnonReal();
    if (scan !== undefined && scan.slices !== undefined) {
        let scanZip = new JSZip();
        const sopInstanceUIDs = Object.keys(scan.slices);
        let scanNumber = 0;
        for (const sopInstanceUID of sopInstanceUIDs) {
            const slice = scan.slices[sopInstanceUID];
            if (_.isArrayBuffer(slice.arrayBuffer) && _.isString(slice.filename)) {
                const useGzip = (slice.filename as string).endsWith('.dcm.gz');
                let usedFilename = anonymizeScan ? `anon${lpad(scanNumber, 3)}.${useGzip ? 'dcm.gz' : 'dcm'}` : slice.filename;
                let usedFiledata = anonymizeScan ? anonymizeSlice(slice.arrayBuffer, anonymizationMap) : slice.arrayBuffer;
                scanZip.file(usedFilename, useGzip ? compressGZip(usedFiledata) : usedFiledata);
            }
            scanNumber++;
        }

        if (Object.keys(scanZip.files).length > 0) {
            const resultFile = yield call([scanZip, 'generateAsync'], { type: 'blob' });
            saveFileOnUserDevice(zipFilename, resultFile);
        }
    }
}

function* watchLiveReviewAutoLoad() {
    // first, wait for confirmation that we're in a live review page
    const liveReviewQueries: { queryV1: LiveReviewQueryV1, queryV2: LiveReviewQueryV2 } = yield take(Store.setLiveReviewQueries);
    const { queryV1, queryV2 } = liveReviewQueries;
    if (!queryV1 && !queryV2) {
        // no live review queries, nothing to do
        return;
    }

    // if an app auth is not yet set as required, make it so
    if (queryV2) {
        const requiredBackend = getRequiredLiveReviewBackend(queryV2);
        const appAuth = getAppAuthByTier(requiredBackend.tier);
        if (!appAuth.isLoggedIn) {
            // mark this auth as being required -- note that it might already be required, or might already be in the process of being logged in
            yield put({ type: Store.setAuthStateAsRequired, appAuthName: appAuth.appName });
        }
    }

    // wait for the possible new login to finish
    yield waitForLoginsToFinish();

    // load livereview files
    const isLiveReviewDownloadStarted: boolean = yield select(selectors.isLiveReviewDownloadInitialized());
    if (!isLiveReviewDownloadStarted) {
        // prioritize v1 query (direct azure fileshare access), if not available then try v2 query (backend api file download)
        try {
            if (queryV1) {
                // v1 queries don't need access to contouring backend, so backend client can be set to null
                const liveReviewBackendClient = null;
                const liveReviewClient = new LiveReviewClient(liveReviewBackendClient);

                yield put({ type: setLiveReviewIsDownloading, isDownloadingLiveReviewFiles: true });
                const requests: LiveReviewV1FileRequest[] = yield call([liveReviewClient, 'getLiveReviewFilesV1'], queryV1);
                for (const request of requests) {
                    request.downloadKey = Store.liveReviewDownloadKey;
                    yield put({ type: Store.receiveDownloadAddFile, request: request });
                }
            }
            else if (queryV2) {
                const liveReviewBackendClient = getRequiredLiveReviewBackendClient(queryV2);
                const liveReviewClient = new LiveReviewClient(liveReviewBackendClient);
                yield put({ type: setLiveReviewIsDownloading, isDownloadingLiveReviewFiles: true });
                const paths: string[] = yield call([liveReviewClient, 'getLiveReviewFilesV2Paths'], queryV2);
                for (const path of paths) {
                    const request = new LiveReviewV2FileRequest(path, Store.liveReviewDownloadKey, queryV2);
                    yield put({ type: Store.receiveDownloadAddFile, request: request });
                }
            }
        }
        catch (error) {
            console.error(error);
            yield put({ type: Store.receiveDownloadErrorType, error: new ApiDownloadError('', Store.liveReviewDownloadKey, error) });
        }
    }
}

function* watchOpenTask() {
    while (true) {
        const action: { task: TrainingTask | undefined } = yield take(Store.openTask);
        yield spawn(openTrainingTask, action.task);
    }
}

function* watchDatasetLockRequests() {
    while (true) {
        const action: { datasetImage: DatasetImage, dataset: Dataset, lockAction: LockAction } = yield take(Store.requestDatasetLock);
        yield spawn(requestDatasetLock, action.datasetImage, action.dataset, action.lockAction);
    }
}

function* watchStructureSetLockRequests() {
    while (true) {
        const action: { rtStructLock: RTStructLock, lockAction: LockAction } = yield take(Store.requestStructureSetLock);
        yield spawn(requestStructureSetLock, action.rtStructLock, action.lockAction)
    }
}

function* watchFetchStructureSetLocks() {
    while (true) {
        const action: { clearRequest: string } = yield take(Store.getStructureSetLocks);
        yield spawn(getStructureSetLocks, action.clearRequest);
    }
}

function* watchGradingSaveOperations() {

    // start two different loops that spawn calls that both eventually call saveDatasetGradingsToFile

    const watchSaveStructureSetGradingRequests = function* () {
        while (true) {
            const action: { structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset } = yield take(Store.saveStructureSetGrading);
            yield spawn(saveStructureSetGradingToFile, action.structureSetId, action.ssGrading, action.dataset);
        }
    }

    const watchSaveDatasetGradingsRequests = function* () {
        while (true) {
            const action: { gradingsToSave: datasetFiles.GradingToSave[], dataset: Dataset } = yield take(Store.saveDatasetGradings);
            yield spawn(startSavingDatasetGradingsToFile, action.gradingsToSave, action.dataset);
        }
    }

    // debounce calling the actual gradings file azure disk save operation as that's a relatively slow operation
    const finishDebouncedDatasetGradingSave = function* () {
        yield debounce(DEBOUNCE_GRADINGS_SAVE_MS, Store.debouncedFinishDatasetGradingSave, finishSavingDatasetGradingsToFile);
    }


    yield all([call(watchSaveStructureSetGradingRequests), call(watchSaveDatasetGradingsRequests), call(finishDebouncedDatasetGradingSave)]);


}

function* watchReloadDatasetGradings() {
    while (true) {
        const action: { dataset: Dataset } = yield take(Store.reloadDatasetGradings);
        yield spawn(reloadDatasetGradings, action.dataset);
    }
}

function* watchDatasetDownloads() {
    while (true) {
        // get what dataset we're trying to download
        const action: { azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string } = yield take(Store.requestDownloadDataset);
        yield spawn(loadDataset, action.azureShare, action.reloadMetaFiles, action.datasetId);
    }
}

function* watchImageAndStructureSetDownloads() {
    while (true) {
        const action: { datasetImage: DatasetImage, dataset: Dataset, reloadDataset: boolean | undefined } = yield take(Store.requestImageAndStructureSetDownload);
        yield spawn(downloadImageAndStructureSet, action.datasetImage, action.dataset, action.reloadDataset);
    }
}

function* watchTaskDownloads() {
    while (true) {
        const action: { task: TrainingTask } = yield take(Store.requestTaskDownload);
        yield spawn(downloadTask, action.task);
    }
}

function* watchAnnotationQueries() {
    while (true) {
        const action: { annotationQuery: UrlQuery, workspace: Workspace } = yield take(Store.loadAnnotationQuery);
        yield spawn(downloadAnnotationQueryItems, action.annotationQuery, action.workspace);
    }
}

// watch structure sets to be updated 
function* watchTasksUpload() {
    // throw new Error('FIXME, this doesn\'t do anything, it just gets stuck in an endless loop');
    // while (true) {
    //     // @ts-ignore: TODO: strongly type expected action payload from this yield take call
    //     const action: { tasks: TrainingTasks } = yield take(Store.downloadTasks);
    //     console.log(action.tasks);
    //     yield spawn(downloadTasks, action.tasks[0].storageAccount, action.tasks[0].fileShare);
    // }
}

function* watchSaveScanToUserDevice() {
    while (true) {
        const action: { scanId: string, zipFilename: string, anonymizeScan: boolean } = yield take(Store.requestSaveScanToUserDisk);
        yield spawn(saveScanToUserDevice, action.scanId, action.zipFilename, action.anonymizeScan);
    }
}

function* watchDicomDownloads() {
    // @ts-ignore: the return object from the yield call function is a complex redux saga object
    const queue = yield call(channel);

    // create 5 'worker threads'
    for (let i = 0; i < 5; i++) {
        yield fork(handleDownload, queue);
    }
    while (true) {
        // @ts-ignore: TODO: strongly type expected action payload from this yield take call
        const action = yield take(Store.receiveDownloadAddFile);
        yield put(queue, action);
    }
}

function* handleDownload(chan: any) {
    while (true) {
        // @ts-ignore: the return object from the yield take function is a complex redux saga object
        const action = yield take(chan);
        if (action.request && action.request instanceof LiveReviewV2FileRequest) {
            yield downloadLiveReviewV2DicomFromMVBackendAsync(action);
        }
        else {
            yield downloadDicomAsync(action);
        }
    }
}


function* watchDicomUploads() {
    // @ts-ignore: the return object from the yield call function is a complex redux saga object
    const queue = yield call(channel);

    // create 5 'worker threads'
    const workerThreadCount = getAreConcurrentUploadsEnabled() ? 5 : 1;
    for (let i = 0; i < workerThreadCount; i++) {
        yield fork(handleUpload, queue);
    }
    while (true) {
        // @ts-ignore: TODO: strongly type expected action payload from this yield take call
        const action = yield take(Store.receiveUploadAddFile);
        yield put(queue, action);
    }
}

function* handleUpload(chan: any) {
    while (true) {
        // @ts-ignore: the return object from the yield take function is a complex redux saga object
        const action = yield take(chan);
        yield uploadDicomAsync(action);
    }
}

export default function* rootSaga() {
    // don't actually run root saga until a component tells to initialize the full application
    const action = yield take(Store.initializeApp);
    const location: Location | undefined = _.get(action, 'location', undefined);

    yield all([
        initialize(location),
        watchLiveReviewAutoLoad(),
        watchDatasetDownloads(),
        watchDatasetLockRequests(),
        watchStructureSetLockRequests(),
        watchFetchStructureSetLocks(),
        watchGradingSaveOperations(),
        watchReloadDatasetGradings(),
        watchImageAndStructureSetDownloads(),
        watchTaskDownloads(),
        watchAnnotationQueries(),
        watchDicomDownloads(),
        watchDicomUploads(),
        watchBatchJobs(),
        watchBatchJobRequests(),
        watchStructureTemplates(),
        watchVersion(),
        watchUserUpdate(),
        watchReloadTasks(),
        watchUpdateTask(),
        watchStartTask(),
        watchFinishTask(),
        watchDeleteTask(),
        watchArchiveTask(),
        watchUnarchiveTask(),
        watchTasksUpload(),
        watchOpenTask(),
        watchCurrentDatasetLock(),
        watchSaveScanToUserDevice(),
    ]);
}
