import produce, { original } from 'immer';
import _ from 'lodash';

import { DownloadTask, UploadTask, TransferredFile, TransferredFileType, LocalFileProgress, isLocalFileProgress } from "./file-transfer-task";
import { ContouringTask, ContouringTaskState } from './contouring-task';
import { getDurationString } from '../util';
import { differenceInSeconds } from 'date-fns';
import { AppVersionInfo } from './app-version-info';
import { AppAuthStatesCollection, LogInProcessState } from './auth-state';
import { User } from './user';
import { AzureDownloadError, LoginError, ApiDownloadError } from './errors';
import { instanceOfAzureFileShareRequest, instanceOfApiFileShareRequest } from './requests';
import { ReceivedApiFile, ReceivedAzureFile } from './received-files';
import { Dataset } from '../datasets/dataset';
import { GradingToSave, ScanLockRequests, ScanLocks } from '../datasets/dataset-files';
import WorkState, { Workspace } from './work-state';
import { DatasetGradings } from '../datasets/roi-grading';
import { StructureSet, Roi } from '../dicom/structure-set';
import { SessionNotification } from '../components/common/models/SessionNotification';
import { RTViewerDisplayVersion } from '../environments';
import { PagePatient } from '../components/annotation-page/models/PagePatient';
import { FileLoadError } from './file-load-error';
import { AzureFileInfo } from '../web-apis/azure-files';
import { RoiGuidelines } from '../web-apis/rtviewer-api-client';
import { TrainingTask, isTrainingTask, SortType, Filter } from '../datasets/training-task';
import { RTStructLockDictionary, LockAction, StructureSetLockRequests } from '../datasets/locks';
import { backendTierAppAuths } from '../web-apis/auth';
import { Scan, Slice, getSliceId, getScanId, createScan, updateScan } from './scans';
import { ImageSlice, Image } from '../dicom/image';
import { TrainingUser } from './training-user';
import { LoggedInMsalUser } from '../web-apis/app-auth';
import { UserPermissions } from '../web-apis/user-permissions';
import { UrlQuery } from './url-query';
import { DeploymentConfigInfo } from './deployment-config-info';
import { LabelingInfo } from './labeling-info';

export const initializeApp = 'INITIALIZE_APP';
export const setAppCrashError = 'SET_APP_CRASH_ERROR';
export const setInitialUrlQuery = 'SET_INITIAL_URL_QUERY';
export const clearUrlQuery = 'CLEAR_URL_QUERY';
export const setIsAutoloading = 'SET_IS_AUTOLOADING';
export const setAutoOpenTaskItem = 'SET_AUTO_OPEN_TASK_ITEM';

export const addAuthState = 'ADD_AUTH_STATE';
export const startLogin = 'START_LOGIN';
export const startLogout = 'START_LOGOUT';
export const setAuthStateAsRequired = 'SET_AUTH_STATE_AS_REQUIRED';
export const requestLogOut = 'REQUEST_LOGOUT';
export const setAuthStateAsLoggedIn = 'SET_AUTH_STATE_AS_LOGGED_IN';
export const setUserDetails = 'SET_USER_DETAILS';
export const setUserPermissions = 'SET_USER_PERMISSIONS';

export const receiveDownloadCreated = 'RECEIVE_DOWNLOAD_CREATED'; // Create an empty download task
export const receiveDownloadAddFile = 'RECEIVE_DOWNLOAD_ADD_FILE'; // Add file to a download task
export const deleteDownloadType = 'DELETE_DOWNLOAD';
export const receiveDownloadErrorType = 'RECEIVE_DOWNLOAD_ERROR';
export const receiveFileDownloadedType = 'RECEIVE_FILE_DOWNLOADED';

export const receiveUploadCreated = 'RECEIVE_UPLOAD_CREATED';
export const receiveUploadAddFile = 'RECEIVE_UPLOAD_ADD_FILE';
export const deleteUploadType = 'DELETE_UPLOAD';
export const receiveUploadErrorType = 'RECEIVE_UPLOAD_ERROR';
export const receiveFileUploadedType = 'RECEIVE_FILE_UPLOADED';

export const receiveSliceType = 'RECEIVE_IMAGE_SLICE';
export const receiveFullImageType = 'RECEIVE_FULL_IMAGE';

export const receiveStructureSetType = 'RECEIVE_STRUCTURE_SET';
export const deleteStructureSetType = 'DELETE_STRUCTURE_SET';
export const seenStructureSetType = 'SEEN_STRUCTURE_SET';
export const storeOriginalStructureSet = 'STORE_ORIGINAL_STRUCTURE_SET';
export const fileLoadError = 'FILE_LOAD_ERROR';
export const clearFileLoadErrors = 'CLEAR_FILE_LOAD_ERRORS';

export const changeIsContouringAllowedInStructureSetRois = 'CHANGE_IS_CONTOURING_ALLOWED_IN_STRUCTURE_SET_ROIS';

export const updateContouringTaskType = 'UPDATE_CONTOURING_TASK';
export const dismissContouringTaskType = 'DISMISS_CONTOURING_TASK';

export const unloadScanType = 'UNLOAD_SCAN';
export const unloadStructureSetType = 'UNLOAD_STRUCTURE_SET';
export const unloadAllContouringTasksType = 'UNLOAD_ALL_CONTOURING_TASKS';

export const setBatchJobPaneVisibilityType = 'SET_BATCH_JOB_PANE_VISIBILITY';
export const receiveStartWatchingBatchJobsType = 'RECEIVE_START_WATCHING_BATCH_JOBS';
export const receiveBatchJobsStatusType = 'RECEIVE_BATCH_JOBS_STATUS';
export const batchJobRequestLockType = 'BATCH_JOB_REQUEST_LOCK';
export const batchJobRequestSuccessType = 'BATCH_JOB_REQUEST_SUCCESS';
export const batchJobRequestFailureType = 'BATCH_JOB_REQUEST_FAILURE';

export const setTasksDialogVisibilityType = 'SET_TASKS_DIALOG_VISIBILITY';
export const setTaskDescriptionDialogVisibility = 'SET_TASK_DESCRIPTION_DIALOG_VISIBILITY';
export const setUserSettingsDialogVisibilityType = 'SET_USER_SETTINGS_DIALOG_VISIBILITY';
export const setSupervisorSettingsDialogVisibilityType = 'SET_SUPERVISOR_SETTINGS_DIALOG_VISIBILITY';
export const setUserSettingBackend = 'SET_USER_SETTING_BACKEND';
export const logIntoBackend = 'LOG_INTO_BACKEND'
export const setUserSettingPatientInfoVisibility = 'SET_USER_SETTING_PATIENT_INFO_VISIBILITY';

export const setHelpDialogVisibility = 'SET_HELP_DIALOG_VISIBILITY';
export const setArchivedTasksDialogVisibility = 'SET_ARCHIVED_TASKS_DIALOG_VISIBILITY'

export const addNotificationType = 'ADD_NOTIFICATION';
export const removeNotificationType = 'REMOVE_NOTIFICATION';

export const receiveStartFetchingStructureTemplatesType = 'RECEIVE_START_FETCHING_STRUCTURE_TEMPLATES';
export const setStructureTemplatesType = 'SET_STRUCTURE_TEMPLATES';

export const receiveAppVersionInfo = 'RECEIVE_APP_VERSION_INFO';
export const snoozeNewVersionAlert = 'SNOOZE_NEW_VERSION_ALERT';

export const setLiveReviewQueries = 'SET_LIVE_REVIEW_QUERIES';
export const setLiveReviewIsDownloading = 'SET_LIVE_REVIEW_IS_DOWNLOADING';
export const liveReviewDownloadKey = 'LIVE_REVIEW_DOWNLOAD';

// requestDownloadDataset payload: { azureShare: AzureShareInfo, reloadMetaFiles: boolean, datasetId: string }
export const requestDownloadDataset = 'REQUEST_DOWNLOAD_DATASET';
export const startDatasetDownload = 'START_DATASET_DOWNLOAD';
export const finishDatasetDownload = 'FINISH_DATASET_DOWNLOAD';
export const addDataset = 'ADD_DATASET';

export const requestDatasetLock = 'REQUEST_DATASET_LOCK';
export const startDatasetLockRequest = 'START_DATASET_LOCK_REQUEST';
export const setDatasetLock = 'SET_DATASET_LOCK';
export const undoDatasetLockRequest = 'UNDO_DATASET_LOCK_REQUEST';

export const requestStructureSetLock = 'REQUEST_STRUCTURE_SET_LOCK';
export const startStructureSetLockRequest = 'START_STRUCTURE_SET_LOCK_REQUEST';
export const finishStructureSetLockRequest = 'FINISH_STRUCTURE_SET_LOCK_REQUEST';
export const setStructureSetLocks = 'SET_STRUCTURE_SET_LOCKS';
export const getStructureSetLocks = 'GET_STRUCTURE_SET_LOCKS';

export const setCurrentTask = 'SET_TASK';
export const openTask = 'OPEN_TASK';
export const downloadTasks = 'ADD_TASKS';
export const downloadArchivedTasks = 'ADD_ARCHIVED_TASKS'
export const loadTasks = 'LOAD_TASKS';
export const startUpdatingTask = 'START_UPDATING_TASK';
export const finishUpdatingTask = 'FINISH_UPDATING_TASK';
export const updateTask = 'UPDATE_TASK';
export const startTask = 'START_TASK';
export const finishTask = 'FINISH_TASK';
export const deleteTask = 'DELETE_TASK';
export const archiveTask = 'ARCHIVE_TASK';
export const setTasksSearchText = 'SET_TASKS_SEARCH_TEXT';
export const setSortType = 'SET_SORT_TYPE';
export const setTasksFilters = 'SET_TASKS_FILTERS';
export const unarchiveTask = 'UNARCHIVE_TASK';

export const setTestAndReferenceStructureObjects = 'SET_TEST_AND_REFERENCE_STRUCTURE_OBJECTS';

export const downloadUsers = 'SET_USERS';
export const downloadGTRois = 'SET_GT_ROIS';
export const downloadPatients = 'SET_PATIENTS';
export const downloadRoiGuidelines = 'SET_ROI_GUIDELINES';

export const setDatasetGradings = 'SET_DATASET_GRADINGS';
export const receivePagePatients = 'RECEIVE_PAGE_PATIENTS';
export const startDatasetGradingSave = 'START_DATASET_GRADINGS_SAVE';
export const debouncedFinishDatasetGradingSave = 'DEBOUNCED_FINISH_DATASET_GRADINGS_SAVE';
export const finishDatasetGradingSave = 'FINISH_DATASET_GRADINGS_SAVE';
export const syncStructureSetGrading = 'SYNC_STRUCTURE_SET_GRADING';
export const saveStructureSetGrading = 'SAVE_STRUCTURE_SET_GRADING';
export const saveDatasetGradings = 'SAVE_DATASET_GRADINGS';
export const setStructureSetGrading = 'SET_STRUCTURE_SET_GRADING';
export const reloadDatasetGradings = 'RELOAD_DATASET_GRADINGS';
export const clearModifiedDatasetGradings = 'CLEAR_MODIFIED_DATASET_GRADINGS';

export const requestImageAndStructureSetDownload = 'REQUEST_IMAGE_AND_STRUCTURE_SET_DOWNLOAD';
export const requestTaskDownload = 'REQUEST_TASK_DOWNLOAD';

export const requestSaveScanToUserDisk = 'REQUEST_SAVE_SCAN_TO_USER_DISK';

export const loadAnnotationQuery = 'LOAD_ANNOTATION_QUERY';

export const setLoginError = 'SET_LOGIN_ERROR';

export const setCurrentWorkState = 'SET_CURRENT_WORK_STATE';
export const updateCurrentWorkState = 'UPDATE_CURRENT_WORK_STATE';

export const hideSide = 'TOGGLE_SIDEBAR';
export const setLabeling = 'SET_LABELING';

export const resetLocalFileProgress = 'RESET_LOCAL_FILE_PROGRESS';
export const setLocalFileProgress = 'SET_LOCAL_FILE_PROGRESS;'

export const setDeploymentConfigInfo = 'SET_DEPLOYMENT_CONFIG_INFO';
export const setApplicationPermissions = 'SET_APPLICATION_PERMISSIONS';
export const setLabelingInfo = 'SET_LABELING_INFO';

// TODO: this MUST be made strongly-typed to make interacting with the store easier and safer, preferrably sooner than later
export type StoreState = {
    appAuthStatesCollection?: any,
    urlQuery?: UrlQuery,
    isAutoloading?: boolean;
    /** this is used to automatically open a matching task group item on the tasks list when accessing a task through a direct URL */
    autoOpenTaskItem?: string;
    user?: User,
    trainingUsers?: TrainingUser[],
    scans?: { [scanId: string]: Scan },
    // original DICOM structure sets we've downloaded
    originalStructureSets?: any;    // { [structureSetId: string]: { file: ArrayBuffer, filename: string } }
    downloads?: any,
    uploads?: any,
    contouringTasks?: any,
    trainingTasks?: TrainingTask[], //Tasks for the current dataset
    archivedTrainingTasks?: TrainingTask[]
    gtRois?: string[],
    patients?: any,
    currentTask?: TrainingTask,
    /** a backup version of currentTask used during optimistic save in order to do roll-back on error */
    currentTaskTemporary?: TrainingTask,
    pagePatients?: PagePatient[],
    roiGuidelines?: RoiGuidelines,
    batchJobs?: any,
    notifications?: any,
    newStructureSets?: any,
    isUpdateTaskDialogVisible?: boolean, // Modal dialog for editing tasks
    isTaskDescriptionDialogVisible?: boolean, // Modal dialog for viewing task description
    isUpdatingTask?: boolean, // true if a task is currently being updated or finished
    isUserSettingsDialogVisible?: any,
    isSupervisorSettingsDialogVisible?: any,
    isHelpDialogVisible?: any,
    isArchivedTasksDialogVisible?: any,
    isBatchJobPaneVisible?: any,
    isBatchJobRequestLocked?: any,
    structureTemplates?: any,
    appVersion?: any,
    isNewAppVersionAvailable?: any,
    newVersionAlertSnoozedSince?: any,
    liveReviewQueries?: any,
    isDownloadingLiveReviewFiles?: any,
    loginError?: any,
    datasetsDownloading?: any,
    datasets?: any,
    // dataset locks by dataset id
    datasetLocks?: any, // { [datasetId: string]: ScanLocks }
    // dataset lock requests by dataset id
    lockRequests?: any, // { [datasetId: string]: ScanLockRequest }
    // structure set locks by structure set id
    structureSetLocks?: RTStructLockDictionary, // { [structureSetId: string]: StructureSetLock }
    // structure set lock requests by structure set id
    structureSetLockRequests?: StructureSetLockRequests , // { [structureSetId: string]: StructureSetLockRequest }
    // dataset gradings by dataset id
    datasetGradings?: any,  // { [datasetId: string]: DatasetGradings }
    isSavingGradings?: any,
    /* we need to keep track of which gradings user has modified in order to properly sync up during a delayed save operation **/
    modifiedDatasetGradings?: any, // { [datasetId: string]: GradingToSave[] }
    // current work set and its state in RTViewer
    currentWorkState?: WorkState,
    fileLoadErrors?: FileLoadError[],
    hideSide?: boolean,
    testStructureSet?: StructureSet,
    referenceStructureSet?: StructureSet,
    testRoi?: Roi,
    referenceRoi?: Roi,
    localFileProgress?: LocalFileProgress,
    deploymentConfigInfo?: DeploymentConfigInfo,
    labelingInfo?: LabelingInfo,
    appCrashError?: Error,
    searchText?: string,
    sortType?: SortType,
    tasksFilters?: Filter[],
}

export const initialState: StoreState = {
    appAuthStatesCollection: new AppAuthStatesCollection(),
    urlQuery: undefined,
    isAutoloading: false,
    autoOpenTaskItem: undefined,
    user: new User(),
    trainingUsers: [],
    scans: {},
    originalStructureSets: {},
    downloads: {},
    uploads: {},
    contouringTasks: {},
    batchJobs: [],
    currentTask: undefined,
    trainingTasks: [],
    archivedTrainingTasks: [],
    gtRois: [],
    patients: [],
    pagePatients: [],
    roiGuidelines: [],
    notifications: [],
    newStructureSets: [],
    isUpdateTaskDialogVisible: false,
    isTaskDescriptionDialogVisible: false,
    isUpdatingTask: false,
    isUserSettingsDialogVisible: false,
    isSupervisorSettingsDialogVisible: false,
    isHelpDialogVisible: false,
    isArchivedTasksDialogVisible: false,
    isBatchJobPaneVisible: false,
    isBatchJobRequestLocked: false,
    structureTemplates: [],
    appVersion: null,
    isNewAppVersionAvailable: false,
    newVersionAlertSnoozedSince: null,
    liveReviewQueries: { queryV1: null, queryV2: null },
    isDownloadingLiveReviewFiles: false,
    loginError: null,
    datasetsDownloading: {},
    datasets: {},
    datasetLocks: {},
    lockRequests: {},
    structureSetLocks: {},
    structureSetLockRequests: {},
    datasetGradings: {},
    isSavingGradings: false,
    modifiedDatasetGradings: {},
    currentWorkState: new WorkState(null, null, false, Workspace.None, false),
    fileLoadErrors: [],
    hideSide: false,
    testStructureSet: undefined,
    referenceStructureSet: undefined,
    testRoi: undefined,
    referenceRoi: undefined,
    localFileProgress: undefined,
    deploymentConfigInfo: undefined,
    labelingInfo: undefined,
    appCrashError: undefined,
    searchText: undefined,
    sortType: undefined,
    tasksFilters: [],
};

export const reducer = (state: any, action: any) => {
    state = state || initialState;
    //  console.log(action.type);
    //  console.log(action);

    return produce(state, (draft: StoreState) => {
        
        if (action.type === addAuthState) {
            const { authState } = action;
            (draft.appAuthStatesCollection as AppAuthStatesCollection).addAuthState(authState);
        }

        if (action.type === startLogin) {
            const { appAuthName } = action;
            const authState = (draft.appAuthStatesCollection as AppAuthStatesCollection).getAppAuthState(appAuthName);
            if (authState && authState.logInProcessState !== LogInProcessState.LoggedIn) {
                authState.logInProcessState = LogInProcessState.LoggingInProgress;
            }
        }

        if (action.type === startLogout) {
            const { appAuthName } = action;
            const authState = (draft.appAuthStatesCollection as AppAuthStatesCollection).getAppAuthState(appAuthName);
            if (authState) {
                authState.isLogoutInProgress = true;
            }
        }

        if (action.type === setAuthStateAsRequired) {
            const { appAuthName } = action;
            (draft.appAuthStatesCollection as AppAuthStatesCollection).setAuthStateAsRequired(appAuthName);
        }

        if (action.type === setAuthStateAsLoggedIn) {
            const { appAuthName } = action;
            (draft.appAuthStatesCollection as AppAuthStatesCollection).setAuthStateAsLoggedIn(appAuthName);
        }

        if (action.type === setUserDetails) {
            const { username, email, permissions, loggedInUser }: { username: string, email: string, permissions: UserPermissions, loggedInUser: LoggedInMsalUser | undefined } = action;
            (draft.user as User).setBasicDetails(username, email);
            (draft.user as User).setPermissions(permissions);
            if (loggedInUser) {
                (draft.user as User).setLoggedInDetails(loggedInUser);
            }
        }

        if (action.type === setUserPermissions) {
            const { permissions } = action;
            (draft.user as User).setPermissions(permissions);
        }

        if (action.type === receiveDownloadCreated) {
            const key = action.downloadKey;
            draft.downloads[key] = new DownloadTask();
        }

        if (action.type === receiveUploadCreated) {
            const { scanId } = action;
            draft.uploads[scanId] = new UploadTask();
            draft.contouringTasks[scanId] = new ContouringTask(scanId);
        }

        if (action.type === receiveDownloadAddFile) {
            const { request } = action;

            let filename: string;
            let key: string;

            if (instanceOfAzureFileShareRequest(request)) {
                filename = request.fileInfo.filename;
                key = request.downloadKey || request.fileInfo.filename.toString();
            }
            else if (instanceOfApiFileShareRequest(request)) {
                filename = request.path;
                key = request.downloadKey || request.path;
            }
            else {
                const errorMsg = `Invalid request object received`;
                console.error(errorMsg);
                console.log(action);
                throw new Error(errorMsg);
            }

            const download: DownloadTask = draft.downloads[key] || new DownloadTask();
            download.files[filename] = new TransferredFile();
            draft.downloads[key] = download;
        }

        if(action.type === hideSide) {
            const { hide } = action;
            draft.hideSide = hide;
        }

        if (action.type === receiveUploadAddFile) {
            const { filename, scanId } = action;
            const upload = draft.uploads[scanId];// || new UploadTask();
            if (upload === undefined) { console.error(`Expected upload task for scanId ${scanId} to exist!`); }

            const tf = new TransferredFile();
            tf.scanId = scanId;
            tf.fileType = TransferredFileType.ImageSlice;
            upload.files[filename] = tf;

            const contouringTask = draft.contouringTasks[scanId];
            contouringTask.totalImagesToUpload = Object.keys(upload.files).length;
            if (contouringTask.contouringState === ContouringTaskState.NotStarted) {
                contouringTask.uploadStartTime = new Date();
                contouringTask.contouringState = ContouringTaskState.UploadingFiles;
            }
        }
        
        /**
         * Update the state of a pagePatient with the given array of pagePatients;
         * @param pagePatients
         * @param action
         * @param draft
         */

        if (action.type === receivePagePatients) {
            const { pagePatients } = action;
            draft.pagePatients = pagePatients;
        }

        if (action.type === deleteDownloadType) {
            const { downloadKey } = action;
            const download = draft.downloads[downloadKey];
            if (download) {

                // collect any scan ids in this download
                const scanIds: string[] = _.uniq(Object.values(download.files).map((f: any) => f.scanId));

                // collect any other downloads that refer to the same scan as we need to delete them all
                const allMatchingDownloadIds: string[] = [ downloadKey ];
                const allDownloadKeys = Object.keys(draft.downloads).filter(k => k !== downloadKey);
                for (const scanId of scanIds) {
                    for (const k of allDownloadKeys) {
                        const d = draft.downloads[k];
                        if (d && _.has(d, 'files') && Object.values(d.files).some((f: any) => f.scanId === scanId)) {
                            allMatchingDownloadIds.push(k);
                        }
                    }
                }

                for (const dId of allMatchingDownloadIds) {
                    draft.downloads[dId] = undefined;
                }
            }
        }

        if (action.type === deleteUploadType) {
            draft.uploads[action.scanId] = undefined;
        }

        if (action.type === receiveFileDownloadedType) {

            const { receivedFile }: { receivedFile: ReceivedAzureFile | ReceivedApiFile } = action;

            let key: string;
            let filename: string;

            if (receivedFile instanceof ReceivedAzureFile) {
                key = receivedFile.downloadKey || receivedFile.fileInfo.toString();
                filename = receivedFile.fileInfo.filename;
            }
            else if (receivedFile instanceof ReceivedApiFile) {
                key = receivedFile.downloadKey || receivedFile.path;
                filename = receivedFile.path;
            }
            else {
                const errorMsg = `Invalid ReceivedFile object received`;
                console.error(errorMsg);
                console.log(action);
                throw new Error(errorMsg);
            }

            const { scanId, structureSetId } = receivedFile;

            const download: DownloadTask = draft.downloads[key];
            let received = 0;
            const filenames = Object.keys((draft.downloads[key] as DownloadTask).files);
            filenames.forEach(f => {
                if (download.files[f].ready || f === filename) {
                    received++;
                }
            });

            const file = download.files[filename];
            file.ready = true;
            file.scanId = scanId;
            if (structureSetId) {
                file.fileType = TransferredFileType.StructureSet;
                file.structureSetId = structureSetId;
            } else {
                file.fileType = TransferredFileType.ImageSlice;
            }

            download.ready = (received === filenames.length);
            download.progressPercentage = received * 100 / filenames.length;
            // download.files[filename] = file;

            draft.downloads[key] = download;
        }

        if (action.type === receiveFileUploadedType) {

            const key = action.scanId;
            const filename = action.filename;

            const upload: UploadTask = draft.uploads[key];

            // ignore everything else here if the upload has already failed
            if (!upload.failed) {
                let sentCount = 0;
                const filenames = Object.keys(upload.files);

                upload.files[filename].ready = true;
                filenames.forEach(fn => {
                    if (upload.files[fn].ready) {
                        sentCount++;
                    }
                })
                upload.ready = (sentCount === filenames.length);
                upload.progressPercentage = sentCount * 100 / filenames.length;

                const contouringTask: ContouringTask = draft.contouringTasks[key];
                if (contouringTask) {
                    contouringTask.contouringState = upload.ready ? ContouringTaskState.PollingForResults : ContouringTaskState.UploadingFiles;
                    contouringTask.imagesUploaded = sentCount;
                    contouringTask.totalImagesToUpload = filenames.length;
                    if (upload.ready && !contouringTask.uploadFinishTime) { contouringTask.uploadFinishTime = new Date(); }
                }
            }
        }

        if (action.type === receiveDownloadErrorType) {
            const { error } = action;

            let key: string;

            if (error instanceof AzureDownloadError) {
                key = error.downloadKey || error.azureInfo.toString();
            }
            else if (error instanceof ApiDownloadError) {
                key = error.downloadKey || error.path;
            }
            else {
                const errorMsg = `Invalid DownloadError object received`;
                console.error(errorMsg);
                console.log(action);
                throw new Error(errorMsg);
            }

            const download: DownloadTask = draft.downloads[key] || new DownloadTask();
            download.failed = true;
            download.error = error.error;
            draft.downloads[key] = download;
        }

        if (action.type === receiveUploadErrorType) {
            const key = action.scanId;
            const error = action.error;

            const upload: UploadTask = draft.uploads[key];

            // if this upload has already failed, don't bother overwriting it
            if (!upload.failed) {
                upload.failed = true;
                upload.error = error;
            }
            // set any possible contouring task to 'error' state (but don't overwrite any existing errors)
            const contouringTask: ContouringTask = draft.contouringTasks[key];
            if (contouringTask && contouringTask.contouringState !== ContouringTaskState.Error) {
                contouringTask.contouringState = ContouringTaskState.Error;
                contouringTask.errorMessage = action.error;
                console.log(action.error);
                contouringTask.uploadFinishTime = new Date();
            }
        }

        if (action.type === receiveSliceType) {
            const { imageSlice, arrayBuffer, filename, fileInfo }: { imageSlice: ImageSlice, arrayBuffer: ArrayBuffer, filename: string, fileInfo?: AzureFileInfo } = action;
            const modality = imageSlice.modality;
            const seriesDescription = imageSlice.seriesDescription;
            const scanId = getScanId(imageSlice, fileInfo);
            const sliceId = getSliceId(imageSlice);
            const slice: Slice = { imageSlice, arrayBuffer, filename, sliceId: sliceId };

            if (!draft.scans) { throw new Error('Scans store not initialized'); }

            const scan = draft.scans[scanId] || createScan(scanId, modality, seriesDescription);
            updateScan(scan, slice);

            draft.scans[scanId] = scan;
        }

        if (action.type === receiveFullImageType) {
            const { image }: { image: Image } = action;

            if (!draft.scans) { throw new Error('Scans store not initialized'); }

            const { scanId } = image;
            const scan = draft.scans[scanId] || createScan(scanId, image.modality, image.seriesDescription);
            updateScan(scan, image);
            draft.scans[image.scanId] = scan;
        }

        if (action.type === unloadScanType) {
            if (!draft.scans) { throw new Error('Scans store not initialized'); }

            delete draft.scans[action.scanId];
        }

        if (action.type === unloadStructureSetType) {
            if (!draft.scans) { throw new Error('Scans store not initialized'); }

            const scan = draft.scans[action.scanId];
            if (scan) {
                delete scan.structureSets[action.structureSetId];
            }
        }

        if (action.type === unloadAllContouringTasksType) {
            draft.contouringTasks = {};
        }

        if (action.type === receiveStructureSetType) {
            const ss: StructureSet = action.structureSet;
            const scanId = ss.scanId;
            const ssId = ss.structureSetId;

            if (!draft.scans) { throw new Error('Scans store not initialized'); }


            if (!draft.scans[scanId]) {
                // need to create an empty scan here in case we're loading DICOM files in a weird order
                draft.scans[scanId] = createScan(scanId, undefined, undefined);
            }
            
            draft.scans[scanId].structureSets[ssId] = ss;

            if (draft.contouringTasks[scanId]) {
                const task = draft.contouringTasks[scanId];
                task.contouringState = ContouringTaskState.Success;
                task.taskFinishTime = new Date();
                if (task.uploadStartTime && task.uploadFinishTime) {
                    const taskTimeLogMessage = `Auto-contouring finished in ${getDurationString(differenceInSeconds(task.taskFinishTime, task.uploadFinishTime))}`
                        + ` (total task time: ${getDurationString(differenceInSeconds(task.taskFinishTime, task.uploadStartTime))})`;
                    console.log(taskTimeLogMessage);
                }

                if (task.matchingStructureSetIds === null) {
                    task.matchingStructureSetIds = [];
                }
                task.matchingStructureSetIds.push(ssId);

                // if the received structure set was previously a contouring task, mark it as a new structure set
                if (draft.newStructureSets.indexOf(ssId) === -1) {
                    draft.newStructureSets.push(ssId);
                }
            }
        }

        if (action.type === deleteStructureSetType) {
            const { structureSet } = action;
            const scanId = structureSet.scanId;
            const ssIdToDelete = structureSet.structureSetId;

            if (!draft.scans) { throw new Error('Scans store not initialized'); }

            if (draft.scans[scanId] !== undefined && draft.scans[scanId].structureSets[ssIdToDelete] !== undefined) {
                delete draft.scans[scanId].structureSets[ssIdToDelete];
            }
        }

        if (action.type === seenStructureSetType) {
            const ssId = action.structureSetId;
            const index = draft.newStructureSets.indexOf(ssId);
            if (index > -1) {
                draft.newStructureSets.splice(index, 1);
            }
        }

        if (action.type === storeOriginalStructureSet) {
            const { structureSetId, file, filename }: { structureSetId: string, file: ArrayBuffer, filename: string }  = action;
            draft.originalStructureSets[structureSetId] = { file, filename };
        }

        if (action.type === changeIsContouringAllowedInStructureSetRois) {
            const ss = action.structureSet;
            const scanId = ss.scanId;
            const ssId = ss.structureSetId;

            // change the value of isContouringAllowed in selected ROIs -- set isContouringAllowedChange to desired changed value, or undefined
            // to derive value from parent structure set's isOriginal value. omit the roi from this dictionary completely if it should not be changed
            const roiIsContouringAllowedChanges: { roiName: string, isContouringAllowedChange: boolean | undefined }[]  = action.roiIsContouringAllowedChanges;

            if (!draft.scans || !draft.scans[scanId] || !draft.scans[scanId].structureSets || !draft.scans[scanId].structureSets[ssId]) {
                return;
            }

            const structureSet: StructureSet = draft.scans[scanId].structureSets[ssId];
            const changedRoiNames = roiIsContouringAllowedChanges.map(r => r.roiName);
            for (const roiKey of Object.keys(structureSet.rois)) {
                const roi = structureSet.rois[roiKey];
                if (changedRoiNames.includes(roi.name)) {
                    const matchingChange = roiIsContouringAllowedChanges.find(r => r.roiName === roi.name)!;
                    roi.isContouringAllowedOverride = matchingChange.isContouringAllowedChange !== undefined ? matchingChange.isContouringAllowedChange : roi.structureSet.isOriginal;
                }
            }
        }

        if (action.type === updateContouringTaskType) {
            const { scanId, newContouringState, errorMessage } = action;
            const contouringTask = draft.contouringTasks[scanId];

            contouringTask.contouringState = newContouringState;

            if (errorMessage) { contouringTask.errorMessage = errorMessage; }

            if (newContouringState === ContouringTaskState.Success || newContouringState === ContouringTaskState.Failed || newContouringState === ContouringTaskState.Error) {
                contouringTask.taskFinishTime = new Date();
            }
        }

        if (action.type === dismissContouringTaskType) {
            const { scanId } = action;
            const contouringTask = draft.contouringTasks[scanId];
            if (contouringTask) { contouringTask.isDismissed = true }
        }

        if (action.type === setUserSettingsDialogVisibilityType) {
            draft.isUserSettingsDialogVisible = action.value as boolean;
        }

        if (action.type === setTasksDialogVisibilityType) {
            draft.isUpdateTaskDialogVisible = action.value as boolean;
        }

        if (action.type === setTaskDescriptionDialogVisibility) {
            draft.isTaskDescriptionDialogVisible = action.value as boolean;
        }

        if (action.type === setSupervisorSettingsDialogVisibilityType) {
            draft.isSupervisorSettingsDialogVisible = action.value as boolean;
        }

        if (action.type === setUserSettingBackend) {
            const { backend } = action;
            draft.user!.currentBackend = backend;
        }

        if (action.type === logIntoBackend) {
            const { backend } = action
            draft.user!.currentBackend = backend;
        }

        if (action.type === setUserSettingPatientInfoVisibility) {
            const { showPatientInfo } = action;
            draft.user!.showPatientInfo = showPatientInfo;
        }

        if (action.type === setHelpDialogVisibility) {
            draft.isHelpDialogVisible = action.value as boolean;
        }
        
        if (action.type === setArchivedTasksDialogVisibility) {
            draft.isArchivedTasksDialogVisible = action.value as boolean;
        }

        if (action.type === setBatchJobPaneVisibilityType) {
            draft.isBatchJobPaneVisible = action.value as boolean;
        }

        if (action.type === receiveBatchJobsStatusType) {
            draft.batchJobs = action.batchJobs;
        }

        if (action.type === batchJobRequestLockType) {
            draft.isBatchJobRequestLocked = action.lock;
        }

        if (action.type === setTasksSearchText) {
            draft.searchText = action.searchText;
        }
        
        if (action.type === setSortType) {
            draft.sortType = action.sortType;
        }

        if (action.type === setTasksFilters) {
            draft.tasksFilters = action.tasksFilters;
        }


        if (action.type === addNotificationType) {
            const newNotification: SessionNotification = action.notification;

            // add or update the notification
            const existingNotificationIndex = draft.notifications.findIndex((n: SessionNotification) => n.id === newNotification.id);
            if (existingNotificationIndex !== -1) {
                draft.notifications.splice(existingNotificationIndex, 1, newNotification);
            } else {
                draft.notifications.push(action.notification);
            }
        }

        if (action.type === removeNotificationType) {
            const index = draft.notifications.findIndex((n: SessionNotification) => n.id === action.notificationId);
            if (index !== -1) {
                draft.notifications.splice(index, 1);
            }
        }

        if (action.type === setStructureTemplatesType) {
            draft.structureTemplates = action.structureTemplates;
        }

        if (action.type === receiveAppVersionInfo) {
            const appVersionInfo = action.appVersionInfo as AppVersionInfo;
            if (draft.appVersion === null) {
                /** Print current version to developer console on receive to make debugging easier. */
                console.log(`RTViewer version: ${appVersionInfo.toString()}, display version: ${RTViewerDisplayVersion}`);
                console.log(appVersionInfo);
                draft.appVersion = appVersionInfo;

                // set correct version to app auths
                Object.values(backendTierAppAuths).forEach(appAuth => appAuth.setAppVersion(appVersionInfo));
            } else {
                if (!(draft.appVersion as AppVersionInfo).isEqual(appVersionInfo)) {
                    draft.isNewAppVersionAvailable = true;
                }
            }
        }

        if (action.type === snoozeNewVersionAlert) {
            draft.newVersionAlertSnoozedSince = new Date();
        }

        if (action.type === setLiveReviewIsDownloading) {
            const { isDownloadingLiveReviewFiles } = action;
            draft.isDownloadingLiveReviewFiles = isDownloadingLiveReviewFiles || false;
        }

        if (action.type === setLiveReviewQueries) {
            const { queryV1, queryV2 } = action;
            draft.liveReviewQueries.queryV1 = queryV1;
            draft.liveReviewQueries.queryV2 = queryV2;
        }

        if (action.type === setLoginError) {
            const { loginError }: { loginError: LoginError | null } = action;
            draft.loginError = loginError;
        }

        // ** DATASETS**

        if (action.type === startDatasetDownload) {
            const { datasetId }: { datasetId: string } = action;
            draft.datasetsDownloading[datasetId] = true;
        }

        if (action.type === finishDatasetDownload) {
            const { datasetId }: { datasetId: string } = action;
            draft.datasetsDownloading[datasetId] = false;
        }

        if (action.type === addDataset) {
            const { dataset, overrideGradings }: { dataset: Dataset, overrideGradings: boolean } = action;
            const datasetId = dataset.getDatasetId();
            draft.datasets[datasetId] = dataset;

            // also save gradings if the override flag is on
            if (overrideGradings) {
                draft.datasetGradings[datasetId] = dataset.metaFiles.gradings;
            }
        }

        if (action.type === startDatasetLockRequest) {
            const { datasetId, seriesId, lockAction }: { datasetId: string, seriesId: string, lockAction: LockAction } = action;
            let datasetRequests: ScanLockRequests = draft.lockRequests[datasetId];
            if (datasetRequests === undefined) {
                draft.lockRequests[datasetId] = {};
                datasetRequests = draft.lockRequests[datasetId];
            }
            datasetRequests[seriesId] = lockAction;
        }

        if (action.type === startStructureSetLockRequest) {
            const { structureSetId, lockAction }: { structureSetId: string, lockAction: LockAction } = action;
            const structureSetRequests = draft.structureSetLockRequests!;
            structureSetRequests[structureSetId] = lockAction;
        }

        if (action.type === finishStructureSetLockRequest) {
            // this reducer is currently unused
            const { structureSetId }: { structureSetId: string } = action;
            const structureSetRequests = draft.structureSetLockRequests!;
            delete structureSetRequests[structureSetId];
        }

        if (action.type === setDatasetLock) {
            const { datasetId, scanLocks }: { datasetId: string, scanLocks: ScanLocks, clearRequests?: boolean } = action;

            // override all existing locks with received ones for this dataset
            draft.datasetLocks[datasetId] = scanLocks;
            // clear fulfilled lock requests for this dataset
            const requests: ScanLockRequests = draft.lockRequests[datasetId];
            if (requests) {
                const seriesIds = Object.keys(requests);
                for (let i = 0; i < seriesIds.length; i++) {
                    const seriesId = seriesIds[i];
                    if ((requests[seriesId] === LockAction.Lock && scanLocks[seriesId])
                        || (requests[seriesId] === LockAction.Unlock && scanLocks[seriesId] !== draft.user!.username)) {
                        delete draft.lockRequests[datasetId][seriesId];
                    }
                }
            }
        }

        if (action.type === setStructureSetLocks) {
            const { structureSetLocks, clearRequestId }: { structureSetLocks: RTStructLockDictionary, clearRequestId: string | undefined } = action;

            if (!structureSetLocks) {
                throw new Error('Undefined structure set locks!');
            }

            draft.structureSetLocks = structureSetLocks;

            // remove specified structure set lock request
            if (clearRequestId) {
                delete draft.structureSetLockRequests![clearRequestId];
            }
        }


        if (action.type === undoDatasetLockRequest) {
            const { datasetId, seriesId }: { datasetId: string, seriesId: string } = action;
            let datasetRequests: ScanLockRequests = draft.lockRequests[datasetId];
            if (datasetRequests && datasetRequests[seriesId]) {
                delete draft.lockRequests[datasetId][seriesId]
            }
        }

        if (action.type === setCurrentWorkState) {
            const { workState }: { workState: WorkState } = action;
            draft.currentWorkState = workState;
        }

        if (action.type === updateCurrentWorkState) {
            const { type, ...updatedWorkStateProps } = action;
            const workState = draft.currentWorkState;
            for (const key in updatedWorkStateProps) {
                if (workState && key in workState) {
                    (workState as any)[key] = (updatedWorkStateProps as any)[key];
                }
            }
        }

        if (action.type === setDatasetGradings) {
            const { datasetId, datasetGradings }: { datasetId: string, datasetGradings: DatasetGradings } = action;
            draft.datasetGradings[datasetId] = datasetGradings;
        }

        if (action.type === setCurrentTask) {
            const { task }: { task: TrainingTask | undefined} = action;
            draft.currentTask = task;
        }

        if (action.type === downloadUsers) {
            const { users }: { users: TrainingUser[] } = action;

            if (draft.trainingUsers === undefined) { throw new Error('Users collection not initialized'); }

            // add users to list of users if they are not already in the list
            users.forEach(user => {
                if (!draft.trainingUsers!.find(u => u.userId === user.userId)) {
                    draft.trainingUsers!.push(user);
                }
            });
        }

        if (action.type === downloadRoiGuidelines)  {
            const { roiGuidelines }: { roiGuidelines: RoiGuidelines | undefined } = action;
            draft.roiGuidelines = roiGuidelines;
        }

        //Tasks actions Download, Create, Update and Delete.
        // add task to list of tasks
        if (action.type === downloadTasks) {
            const { tasks }: { tasks: TrainingTask[] } = action;
            draft.trainingTasks = tasks;
            // // add or replace tasks
            // tasks.forEach(task => {
            //     const index = draft.trainingTasks.findIndex((t: TrainingTask) => t.id === task.id);
            //     if (index !== -1) {
            //         draft.trainingTasks[index] = task;
            //     } else {
            //         draft.trainingTasks.push(task);
            //     }
            // });
        }

        if (action.type === downloadArchivedTasks) {
            const { tasks } : { tasks: TrainingTask[] } = action;
            draft.archivedTrainingTasks = tasks;
        }

        // Download GT ROIs available for current Storage account and file share
        if (action.type === downloadGTRois) {
            const { gtRois }: { gtRois: string[] } = action;
            // replace the gt rois with the new ones
            draft.gtRois = gtRois;
        }

        // Download Patients available for current Storage account and file share
        if (action.type === downloadPatients) {
            const { patients }: { patients: string[] } = action;
            // replace the patients with the new ones
            draft.patients = patients;
        }

        if (action.type === startUpdatingTask) {
            draft.isUpdatingTask = true;

            // optimistically set local modifications to the matching task object while the actual saving process
            // is happening in the backend
            const { task } = action;
            if (task) {

                // update the entire training tasks collection
                if (draft.trainingTasks && isTrainingTask(task)) {
                    const matchingTaskIndex = (draft.trainingTasks as TrainingTask[]).findIndex((t: TrainingTask) => t.id === task.id);
                    if (matchingTaskIndex !== -1) {
                        draft.trainingTasks[matchingTaskIndex] = task;
                    }
                }

                // update the currentTask object, assign currentTaskTemporary for rollback safety
                if (draft.currentTask && draft.currentTask.id === task.id) {
                    draft.currentTaskTemporary = _.cloneDeep(draft.currentTask);
                    draft.currentTask = task;
                }
            }
        }

        if (action.type === finishUpdatingTask) {
            // update operation has failed ONLY if updatedSucceeded was specifically set to FALSE
            const updatedSucceeded = action.updatedSucceeded === undefined || action.updatedSucceeded === true;

            if (!updatedSucceeded && draft.currentTaskTemporary) {
                // perform rollback
                draft.currentTask = _.cloneDeep(draft.currentTaskTemporary);
                if (draft.trainingTasks) {
                    const matchingTaskIndex = (draft.trainingTasks as TrainingTask[]).findIndex((t: TrainingTask) => t.id === draft.currentTaskTemporary!.id);
                    if (matchingTaskIndex !== -1) {
                        draft.trainingTasks[matchingTaskIndex] = _.cloneDeep(draft.currentTaskTemporary);
                    }
                }
            }

            draft.currentTaskTemporary = undefined;
            draft.isUpdatingTask = false;
        }

        if (action.type === startDatasetGradingSave) {
            const { gradingsToSave, dataset }: { gradingsToSave: GradingToSave[], dataset: Dataset } = action;
            const datasetId = dataset.getDatasetId();
            const allGradingsToSave: GradingToSave[] = draft.modifiedDatasetGradings[datasetId] || [];

            draft.isSavingGradings = true;

            for (const gradingToSave of gradingsToSave) {
                const gradingChangeToSameStructureSet = allGradingsToSave.find(g => g.ssId === gradingToSave.ssId);
                if (gradingChangeToSameStructureSet) {
                    // remove previous modification to same structure set
                    _.pull(allGradingsToSave, gradingChangeToSameStructureSet);
                }
                allGradingsToSave.push(gradingToSave);
            }
            draft.modifiedDatasetGradings[datasetId] = allGradingsToSave;
        }

        if (action.type === finishDatasetGradingSave) {
            const { wasSuccessful, gradingsToSave, dataset }: { wasSuccessful: boolean, gradingsToSave: GradingToSave[], dataset: Dataset } = action;
            draft.isSavingGradings = false;

            // clear modified gradings if save was successful, assuming there's been no new changes since
            if (wasSuccessful) {
                const datasetId = dataset.getDatasetId();
                const currentModifiedGradings: GradingToSave[] = draft.modifiedDatasetGradings[datasetId] || [];
                if (_.isEqual(gradingsToSave, currentModifiedGradings)) {
                    delete draft.modifiedDatasetGradings[datasetId];
                }
            }
        }

        if (action.type === clearModifiedDatasetGradings) {
            draft.modifiedDatasetGradings = {};
        }

        /**
         * Synchronizes a structure set's matching grading sheet, if any, to match
         * the current ROI set in the structure set.
         */
        if (action.type === syncStructureSetGrading) {
            const { structureSet, dataset }: { structureSet: StructureSet, dataset: Dataset } = action;

            const datasetId = dataset.getDatasetId();
            const gradings: DatasetGradings | undefined = draft.datasetGradings[datasetId];
            if (gradings) { // Update gradings for the structure set
                const ssGrading = gradings.structureSets[structureSet.structureSetId];
                if (ssGrading) {
                    const ssRoiNrs = Object.keys(structureSet.rois);
                    let gradingRoiNrs = Object.keys(ssGrading.rois);

                    // 1. Delete gradings if ROI is deleted
                    const deletedRoiNrs = gradingRoiNrs.filter(nr => !ssRoiNrs.includes(nr));
                    for (let i = 0; i < deletedRoiNrs.length; ++i) {
                        delete ssGrading.rois[deletedRoiNrs[i]];
                    }
                    // 2. Make sure ROI names are up-to-date in the grading
                    gradingRoiNrs = Object.keys(ssGrading.rois);
                    for (let i = 0; i < gradingRoiNrs.length; ++i) {
                        const roiNr = gradingRoiNrs[i];
                        ssGrading.rois[roiNr].roiName = structureSet.rois[roiNr].name;
                    }
                }
            }
        }

        if (action.type === setStructureSetGrading) {
            const { gradingsToSave, dataset }: { gradingsToSave: GradingToSave[], dataset: Dataset } = action;

            const datasetId = dataset.getDatasetId();
            let gradings: DatasetGradings | undefined = draft.datasetGradings[datasetId];
            if (gradings === undefined) {
                gradings = new DatasetGradings();
                draft.datasetGradings[datasetId] = gradings;
            }

            for (const gradingToSave of gradingsToSave) {
                const { ssGrading, ssId } = gradingToSave;
                if (ssGrading) {
                    gradings.structureSets[ssId] = ssGrading;
                }
                else if (!ssGrading && gradings.structureSets[ssId]) {
                    delete gradings.structureSets[ssId];
                }
            }
        }

        if (action.type === fileLoadError) {
            const { errorMessage, fileName } : { errorMessage: string, fileName: string } = action;
            const newFileLoadError: FileLoadError = { errorMessage, fileName };
            if (draft.fileLoadErrors) {
                draft.fileLoadErrors.push(newFileLoadError);
            }
        }

        if (action.type === clearFileLoadErrors) {
            draft.fileLoadErrors = [];
        }

        if (action.type === setTestAndReferenceStructureObjects) {
            // set test/reference structure set/roi but only if they've been set in the action object
            for (const target of ['testStructureSet', 'referenceStructureSet', 'testRoi', 'referenceRoi']) {
                if (_.has(action, target) && _.has(draft, target)) {
                    (draft as any)[target] = action[target];
                }
            }
        }

        if (action.type === resetLocalFileProgress) {
            draft.localFileProgress = undefined;
        }

        if (action.type === setLocalFileProgress) {
            const localFileProgress = { progress: action.progress, total: action.total };
            if (isLocalFileProgress(localFileProgress)) {
                draft.localFileProgress = localFileProgress;
            }
        }

        if (action.type === setAppCrashError) {
            draft.appCrashError = action.error;
        }

        if (action.type === setInitialUrlQuery) {
            const { urlQuery } = action;
            if (urlQuery === undefined || urlQuery instanceof UrlQuery) {
                draft.urlQuery = action.urlQuery;
            }
        }

        if (action.type === clearUrlQuery) {
            draft.urlQuery = undefined;
        }

        if (action.type === setIsAutoloading) {
            draft.isAutoloading = !!action.isAutoloading;
        }

        if (action.type === setAutoOpenTaskItem) {
            draft.autoOpenTaskItem = _.isString(action.autoOpenTaskItem) ? action.autoOpenTaskItem : undefined;
        }

        if (action.type === setDeploymentConfigInfo) {
            const { deploymentConfigInfo } = action;
            draft.deploymentConfigInfo = deploymentConfigInfo;
        }

        if (action.type === setApplicationPermissions) {
            // const { applicationPermissions }: { applicationPermissions: ApplicationPermissions } = action;
            // draft.applicationPermissions = applicationPermissions;
            throw new Error('not implemented');
        }

        if (action.type === setLabelingInfo) {
            const { labelingInfo } = action;
            draft.labelingInfo = labelingInfo;
        }

    });
};
